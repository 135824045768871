import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppLoaderService } from 'src/app/shared/components/componentsAsService/app-loader/app-loader.service';
import { finalize } from "rxjs/operators";
import { AuthenticationService } from '../shared/services/authentication.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  activeRequests: number = 0;

  constructor(private loadingScreenService: AppLoaderService, private authenticationService: AuthenticationService) {

  }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //const token: string = localStorage.getItem('token');
      let currentUser = this.authenticationService.currentUserValue;
      if (currentUser && currentUser.Token) {
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + currentUser.Token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

      if (this.activeRequests === 0) {
        this.loadingScreenService.open();
      }
      this.activeRequests++;

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                }
                return event;
            }),
          finalize(() => {
            this.activeRequests--;
            if (this.activeRequests === 0) {
              this.loadingScreenService.close();
            }
          })
          );
    }
}
