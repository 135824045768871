<div class="model-box-details">
<h1 mat-dialog-title 
>Booking {{data.status?'Success':'Failed'}}</h1>

<div mat-dialog-content style="font-size: 16px;text-align: center;">
  {{data.status?'Booking Id -':''}}
    {{data.status?data.bookingId:data.message}}
</div>

<div mat-dialog-actions class="modal-button">
  <button mat-button (click)="onNoClick()">OK</button>
 </div>
</div>