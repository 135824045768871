import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core"
import { isUndefined } from 'util'
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api'
import { Observable, of, Observer } from 'rxjs'
import { CommonRequestService } from 'src/app/shared/services/http/common-request.service';


@Component({
  selector: "seat-layout",
  templateUrl: "./seat-layout.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['seat-layout.component.scss']
})
export class SeatLayoutComponent implements OnInit {

  constructor(
    private _commonRequestService: CommonRequestService

  ) {
  }


  @Input() seats: any;
  @Input() zIndex: any;
  @Input() busesSearchResponse: any;
  @Output() onSelectSeat = new EventEmitter<any>();

  rowSeatValues: any=[];
  busLayoutHtmlTmpl = '';// This variable is used for seat layout template 
  maxNoOfColumns = 0; // maximun no of columns out of all available rows;

  missingPathWayRowNO = -1;
  missingPathWayRowNOTmp = 0
  isfirtRow = true;
  isDriverSeat = true;
  columnSeatValues = [];
  seatLayOutMax = new Array();
  pathComparision = 0;

  select: boolean = false;
  selectedSeat: any = [];

  ngOnInit() {
    if (this.seats) {
      this.generateLayout();
    }
  }


  // Function to get the missing number 
  getMissingNo(numbers, totalCount) {

     let expectedSum = totalCount * ((totalCount + 1) / 2);
     let actualSum = 0;
    numbers.forEach((x) => 
      {
        actualSum = actualSum + x;
      });

     return expectedSum - actualSum;

    //var total = (n + 1) * (n + 2) / 2;
    //for (let i = 0; i < n; i++)
    //  total = total- a[i];
    //return total;
  }

  generateLayout() {
    /*
    * finding path way logic start from here
    */

    //this.seats.forEach((columnSeats, rowIndex) => {
    //  if (this.isfirtRow) {
    //    this.missingPathWayRowNOTmp = rowIndex;
    //    this.isfirtRow = false;
    //  } else {
    //    if ((this.missingPathWayRowNOTmp) + 1 != rowIndex) {
    //      this.missingPathWayRowNO = (this.missingPathWayRowNOTmp) + 1;
    //    } else {
    //      this.missingPathWayRowNOTmp = (this.missingPathWayRowNOTmp) + 1;
    //    }
    //  }
    //  this.maxNoOfColumns = columnSeats.length > this.maxNoOfColumns ? columnSeats.length : this.maxNoOfColumns;
    //});
    let min, max,temparray=[];

    this.seats.forEach((columnSeats, rowIndex) => {
      if (columnSeats) {
        columnSeats.selected = false;
      }
    });

    this.seats.forEach((columnSeats, rowIndex) => {
      if (temparray.indexOf(columnSeats.Row) === -1) {
        temparray.push(columnSeats.Row);
      }
    });

    this.missingPathWayRowNO = this.getMissingNo(temparray.sort(), temparray.length);

    /*
    * finding path way logic end from here
    */


    /*
    * Loop for unique rows and culumn and keeping in array for display we are using this array
    */
    this.seats.forEach((columnSeats, rowIndex) => {
      if (this.rowSeatValues.indexOf(columnSeats.Row) === -1) {
        this.rowSeatValues.push(columnSeats.Row);

      }
      //if ($.inArray(seat.Row, rowSeatValues) === -1) {
      //  rowSeatValues.push(seat.Row);
      //}

      //if ($.inArray(seat.Column, columnSeatValues) === -1) {
      //  columnSeatValues.push(seat.Column);
      //}
      if (this.columnSeatValues.indexOf(columnSeats.Column) === -1) {
        this.columnSeatValues.push(columnSeats.Column);
      }

    });


    /*
    * To display seat layout in the form of matrix for that creating empty matrix with the help of unique rows and columns
    */
    var count = 0;
    this.rowSeatValues.forEach((row, columnIndex) => {
      this.seatLayOutMax[row] = new Array();
      this.columnSeatValues.forEach((seat, columnIndex) => {
        this.seatLayOutMax[row][seat] = "";
      });
    });



    /*
    * Asigning seat object to matrix
    */
    this.seats.forEach((seat, columnIndex) => {
      this.seatLayOutMax[seat.Row][seat.Column] = seat
    });
debugger;

    /*
    * seat display html logic 
    */
    //var pathComparision = 0;
    //busLayoutHtmlTmpl += '<table>';
    //seatLayOutMax.forEach((row, index) => {
    //  busLayoutHtmlTmpl += '<tr>';
    //  if (zIndex == 0 && isDriverSeat) {
    //    busLayoutHtmlTmpl += '<td><div class="seat-sprite driver-seat"></div></td>'; //this block is for dispaly driver seat
    //  } else {
    //    busLayoutHtmlTmpl += '<td><div></div></td>'; //giving empty speace not driver 
    //  }
    //  isDriverSeat = false;
    //  row.forEach((col, ind) => {
    //    var seat = col;
    //    pathComparision = seat.Row;
    //    if (seat != "") {
    //      var l = seat.Length;
    //      var w = seat.Width;
    //      if (l == 1 && w == 1) {
    //        busLayoutHtmlTmpl += '<td><div>seater</div></td>'; // Here have to show Seater/Semi Sleeper
    //      } else if (l == 1 && w == 2) {
    //        busLayoutHtmlTmpl += '<td><div>vertical sleeper</div></td>'; // Here have to show Vertical Sleeper
    //      } else if (l == 2 && w == 1) {
    //        if (rowSeatValues.length == 1) { //if row size one in case sleeps we are changing seat to vartical
    //          busLayoutHtmlTmpl += '<td rowspan="2"><div></div></td>'; // Here have to show Vertical Sleeper
    //        } else {

    //          busLayoutHtmlTmpl += '<td><div><a *ngIf="false"><img alt = "" src = "../assets/images/bseat.png" ></a><a *ngIf="!select"><img alt = "" src = "../assets/images/fseat.png" ></a></div></td> '; // Here have to show Horizontal Sleeper
    //        }
    //      } else {
    //        //l = 2 && w = 2 or other combo will never happen
    //      }
    //      busLayoutHtmlTmpl += '</td>';
    //    } else {
    //      busLayoutHtmlTmpl += '<td></td>';
    //    }
    //  });
    //  busLayoutHtmlTmpl += '</tr>';
    //  if (missingPathWayRowNO == (pathComparision) + 1) {
    //    busLayoutHtmlTmpl += '<tr><td><div>path</div></td><td></td></tr>';  // this is paht way
    //  }

    //});
    //isDriverSeat = false;
    //busLayoutHtmlTmpl += '</table>';
    //return busLayoutHtmlTmpl;
  }


  //Fare Calculation

  onSelect(event: any): void {
    this.selectedSeat = event;
    this.onSelectSeat.emit(this.selectedSeat);
  }

}
