import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './shared/services/authentication.service';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // check if route is restricted by role
            
            if(currentUser.SecreenConfiguration)
            {
                var found = false;
                for(var i = 0; i < currentUser.SecreenConfiguration.length; i++) {
                    if (currentUser.SecreenConfiguration[i].IsEnabled && currentUser.SecreenConfiguration[i].Name == route.routeConfig.path) {
                        found = true;
                        break;
                    }
                }
            }
            if (found) {
                // authorised so return true
                return true;
            }
                // role not authorised so redirect to home page
                // this.router.navigate(['/']);
 
            return false;
        }

        
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/home'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}