import { Injectable } from '@angular/core';
import { HttpClient, HttpParams  } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class FlightBookingReportService {
  depositId: number;
  approve: boolean;
  reject: boolean;
  private Get_FlightBookingReports = `${environment.BASE_URL}/api/Flight/GetFlightBookingReport`;
  private Get_FlightTicketDetails = `${environment.BASE_URL}/api/Flight/GetFlightTicketDetails`;

  private Save_DepositRequest = `${environment.BASE_URL}/api/Account/SaveDepositeRequest`;
  private Search_Data = `${environment.BASE_URL}/api/Account/GetUserBasedonRole`;
  private Booking_Status = `${environment.BASE_URL}/api/Account/GetBusBookingStatus`;
  private cancelTicket_API= `${environment.BASE_URL}/api/Flight/CancelFlightTicket`;

  constructor(private httpClient: HttpClient) { }


  searchData(body){
    return this.httpClient.get(this.Search_Data, { params: body })
 }

  getFlightBookingReports(body){
      return this.httpClient.post(this.Get_FlightBookingReports,body)
    }
    getFlightTicketDetails(body){
      return this.httpClient.post(this.Get_FlightTicketDetails,body)
    }
    
  getBusBookingStatus(){
    return this.httpClient.get(this.Booking_Status,{})
  }
  cancelTicket(body){
    return this.httpClient.post(this.cancelTicket_API, body);
  }
  
    setDepositId(value){
     this.depositId = value;
  }
    getDepositId(){
      return this.depositId;
  }
   setApprove(value){
     this.approve = value;
   }
  getApprove(){
   return this.approve;
   }
   setReject(value){
    this.reject = value;
   }

  getReject(){
    return this.reject;
  }
    saveDeposit(saveReq){
      return this.httpClient.put(this.Save_DepositRequest, saveReq)
    }
    rejectDeposit(rejectReq){
      return this.httpClient.put(this.Save_DepositRequest, rejectReq)
    }
}