import {Component, Input,OnInit,  TemplateRef} from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { BusTicketDetailsService } from 'src/app/views/admin-views/dashboard/bus-ticket-details/bus-ticket-details.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FlightBookingReportService } from 'src/app/views/admin-views/dashboard/report/flight-booking/flight-booking-report.service';


@Component({
    selector: 'app-dynamic-template-param',
    templateUrl: './dynamic-template-param.component.html',
    // styleUrls: ['./buses.component.scss']
  })
export class DynamicTemplateParamComponent implements OnInit {
initialTemplate:any;
searchParams: any = {
  IsBus:'',
  TraceId: '',
  SourceId: '',
  DestinationId: '',
  JourneyDate: new Date()

};
routeFromOtherPage:boolean = false;
isTicket: boolean = true;
className:any;

constructor(
  private activatedRoute: ActivatedRoute,
  private busTicketDetailsService:BusTicketDetailsService ,
  private flightBookingReportService:FlightBookingReportService ,
  private _snackBar: MatSnackBar) {
    activatedRoute.params.subscribe(data => {
      // put the code from `ngOnInit` here
      let isBus = data['IsBus'];
      let BookingReferenceId = data['BookingReferenceId'];
    let Email = data['Email'];
    let TicketType = data['TicketType'];
    this.searchParams.BookingReferenceId = BookingReferenceId;
    this.searchParams.Email = Email;
    this.searchParams.TicketType = TicketType;
    this.routeFromOtherPage = true;
    this.searchParams.isBus = isBus;

    if(isBus == 'true'){
      this.getBusTicketDetails();
    }
    else{
      this.getFlightTicketDetails();
    }
    

  });
   

  }

ngOnInit() {

    
   }

getBusTicketDetails(){
  let body = {
    "BookingReferenceId": this.searchParams.BookingReferenceId, // "ETS0S331391213",
    "Mobile": "", //"7013611776",
    "Email": this.searchParams.Email, //"hvijay72@gmail.com",
    "TicketType": "Print"
    }

  this.busTicketDetailsService.getBusTicket(body).subscribe((html:any) =>{
    this.initialTemplate = html.Data.TicketTemplate;
    this.isTicket = false;
  })
        

}

getFlightTicketDetails(){
  let body = {
    "BookingReferenceId": this.searchParams.BookingReferenceId, // "ETS0S331391213",
    "Mobile": "", //"7013611776",
    "Email": this.searchParams.Email, //"hvijay72@gmail.com",
    "TicketType": "Print"
    }

  this.flightBookingReportService.getFlightTicketDetails(body).subscribe((html:any) =>{
    this.initialTemplate = html.Data.TicketTemplate;
    this.isTicket = false;
  })
        

}


openInvoice(){
  let body = {    
      "BookingReferenceId": this.searchParams.BookingReferenceId,
      "IsUserInvoice": false
    }
  
  this.busTicketDetailsService.getBusInvoice(body).subscribe((html:any) =>{
    this.initialTemplate = html.Data;
    
  })
  
}

downloadInvoice(){
var element = document.getElementById('invoicePDF');

  var doc = new jspdf();

  var specialElementHandlers = {
    // '#editor': function (element, renderer) {
    //     return true;
    // }
};

  doc.fromHTML(element, 15,  0.5, {
    'width': 180,
        'elementHandlers': specialElementHandlers
});
doc.save('sample-file.pdf');

}

smsInvoice(){
  let body = {
    "BookingReferenceId": this.searchParams.BookingReferenceId, // "ETS0S331391213",
    "Mobile": "", //"7013611776",
    "Email": this.searchParams.Email, //"hvijay72@gmail.com",
    "TicketType": "Sms"
    }

  this.busTicketDetailsService.getBusTicket(body).subscribe((html:any) =>{
    if(html.IsSuccess){
      this.className = 'green-snackbar';
    } else {
      this.className = 'red-snackbar';
    }

    this._snackBar.open(html.Message, "X", {
      duration: 5000,
      panelClass: [this.className],
      verticalPosition: 'top',
      horizontalPosition:'right',
    });
  })
        
}

emailInvoice(){
  let body = {
    "BookingReferenceId": this.searchParams.BookingReferenceId, // "ETS0S331391213",
    "Mobile": "", //"7013611776",
    "Email": this.searchParams.Email, //"hvijay72@gmail.com",
    "TicketType": "Email"
    }

  this.busTicketDetailsService.getBusTicket(body).subscribe((html:any) =>{if(html.IsSuccess){
    this.className = 'green-snackbar';
  } else {
    this.className = 'red-snackbar';
  }

  this._snackBar.open(html.Message, "X", {
    duration: 5000,
    panelClass: [this.className],
    verticalPosition: 'top',
    horizontalPosition:'right',
  });

    
  })
        
}



}