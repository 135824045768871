export enum Role {
    User = 5,
    Admin = 2,
    Distributor=3,
    Agent=4,
    Manager=6,
    TechSupport=7,
    MarketingExecutive=8,
    MarketingHead=9,
    Accounts=10
  }