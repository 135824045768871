import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { DashboardService } from './views/admin-views/dashboard/dashboard.service';


import { AuthenticationService } from './shared/services/authentication.service';
import { Router } from '@angular/router';

import { User } from 'src/app/model/user.model';
import { Role } from './model/role.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
 loadHeaderFooter:boolean = true;
 loadAgentHeader:boolean = false;
 currentUser: User;
  constructor(private location: Location, private dashboardService:DashboardService,
    private router: Router,
        private authenticationService: AuthenticationService        
        ) {
          this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  //  localStorage.removeItem('token');
 }

 ngOnInit() {
    this.dashboardService.getValue().subscribe(res =>{
     this.loadHeaderFooter = res;
   })
 }
 

 logout() {
  this.authenticationService.logout();
  this.router.navigate(['/home']);
}

}
