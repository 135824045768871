import {Component, Input,OnInit,  TemplateRef} from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
    selector: 'app-dynamic-template',
    templateUrl: './dynamic-template.component.html',
    // styleUrls: ['./buses.component.scss']
  })
export class DynamicTemplateComponent implements OnInit {
initialTemplate:any;

constructor(private dataService:DataService ) {

  }

ngOnInit() {
        this.initialTemplate = this.dataService.inputData; 
    
   }
}