        <header class="dashboard-header">
          <div class="header-sec">
            <div class="logo">
              <!-- need to redirect to dashboard home page -->
              <a class="navbar-brand u-header__navbar-brand-default u-header__navbar-brand-center u-header__navbar-brand-text-white mr-0 mr-xl-5"
                href="javascript:history.go(0)" aria-label="MyTravel">
                
                <span class="u-header__navbar-brand-text">Chalofly.com</span>
              </a>
            </div>
            <div class="user-settings">
                <div class="header-navs">
                  <a mat-button *ngIf="isBuses" [routerLink]="'/dashboard/buses'"> Buses </a>
                  <a mat-button *ngIf="isFlights" [routerLink]="'/dashboard/flights'"> Flights </a>
                  <a mat-button *ngIf="isReportsMenu" [matMenuTriggerFor]="reportMenu"> Reports </a>
                  <mat-menu #reportMenu="matMenu">
                    <button *ngIf="isBusTicketDetails" mat-menu-item [routerLink]="'/dashboard/bus-ticket-details'">Bus/Flight ticket details</button>
                    <button *ngIf="isBusBookingReport" mat-menu-item [routerLink]="'/dashboard/bus-booking-report'">Bus Booking Report</button>
                    <button *ngIf="isFlightBookingReport" mat-menu-item [routerLink]="'/dashboard/flight-booking-report'">Flight Booking Report</button>
                    <button *ngIf="isMarkupCommissionBookingReport" mat-menu-item [routerLink]="'/dashboard/markup-commission-report'">Markup Commission Report</button>
                    <button  *ngIf="isCancelTicket" mat-menu-item [routerLink]="'/dashboard/cancel-ticket'">Cancel ticket</button>
                  </mat-menu>
                  <a mat-button *ngIf="isAccounts" [matMenuTriggerFor]="menu"> {{accountsOrAdminLabel}} </a>
                  <mat-menu #menu="matMenu">
                    <button *ngIf="isSupplier" mat-menu-item [routerLink]="'/dashboard/supplier'">Supplier</button>
                    <button *ngIf="isCategory" mat-menu-item [routerLink]="'/dashboard/category'">Category</button>
                    <button *ngIf="isOperators" mat-menu-item [routerLink]="'/dashboard/operators'">Operators</button>
                    <button *ngIf="isBusCommission" mat-menu-item [routerLink]="'/dashboard/bus-commission'">{{busCommissionOrMarkupLabel}}</button>
                    <button  *ngIf="isFlightsCommission" mat-menu-item [routerLink]="'/dashboard/flight-commission'">{{flightCommissionOrMarkupLabel}}</button>
                    <button *ngIf="isBankDetails" mat-menu-item [routerLink]="'/dashboard/bank-details'">Bank Details</button>
                    <button *ngIf="isUserBankDetails" mat-menu-item [routerLink]="'/dashboard/bank-user-details'">User Bank Details</button>
                    <button  *ngIf="isAgentBankDetails" mat-menu-item [routerLink]="'/dashboard/agent-bank-details'">Agent Bank Details</button>
                  </mat-menu>
                  <a mat-button [matMenuTriggerFor]="umenu" *ngIf="isRegister || isUserList || isAgentList || isDistributorList || isEmployeeList"> User Info </a>
                   <mat-menu #umenu="matMenu">
                    <button *ngIf="isRegister" mat-menu-item [routerLink]="'/dashboard/registration'">Registration</button>
                    <button *ngIf="isUserList" mat-menu-item [routerLink]="'/dashboard/user-list'">User List</button>
                    <button *ngIf="isAgentList"  mat-menu-item [routerLink]="'/dashboard/agent-list'">{{agentOrDistributorListLabel}}</button>
                    <button *ngIf="isDistributorList"  mat-menu-item [routerLink]="'/dashboard/distributor-list'">Distributor List</button>
                    <button *ngIf="isEmployeeList"  mat-menu-item [routerLink]="'/dashboard/employee-list'">Employee List</button>
                  </mat-menu>
                  <a mat-button *ngIf="isTransactions" [matMenuTriggerFor]="tmenu"> Account Transactions </a>
                  <mat-menu #tmenu="matMenu">
                    <button *ngIf="isRequestForm" mat-menu-item [routerLink]="'/dashboard/deposit-form'">Deposit Request Form</button>
                    <button *ngIf="isReports" mat-menu-item [routerLink]="'/dashboard/deposit-report'">Deposit Reports</button>
                    <button *ngIf="isAddRevokebalance" mat-menu-item [routerLink]="'/dashboard/add-revoke-balance'">Add / Revoke Balance</button>
                    <button *ngIf="isAcceptRejectDeposit" mat-menu-item [routerLink]="'/dashboard/accept-reject-deposit'">Accept / Reject Deposit</button>
                    <button *ngIf="isAccountStatement" mat-menu-item [routerLink]="'/dashboard/account-statement'">Account Statement</button>
                  </mat-menu>
                </div>
                <!-- user details -->
                <div class="user-details">
                  <i class="fa-solid fa-user"></i>
                  <div class="user-name">
                    <p>Welcome
                      <span>{{firstName}} {{lastName}} </span>
                      <!-- <span>User ID: </span> -->
                    </p>
                    <p *ngIf="showBalance">Balance: 
                      <span>{{balance}}</span>Rs/-
                      <!-- <mat-icon (click)="refresh()" title="refresh">refresh</mat-icon> -->
                    </p>
                    
                  </div>
                  <div>              
                  </div>
                </div>
                <div class="logout">
                  <a mat-button  [matMenuTriggerFor]="tlogout">  <i class="fa-solid fa-gear" style="font-size: 24px;"></i> <i style="padding-left: 5px;" class="fa-solid fa-caret-down"></i></a>
                  <mat-menu #tlogout="matMenu">
                    <button  mat-menu-item  [routerLink]="'/dashboard/change-password'" title="changePassword">Change Password</button>
                    <button  mat-menu-item  [routerLink]="'/dashboard/myprofile'" title="changePassword">Update Profile</button>
                    <button  mat-menu-item  (click)="logout()" title="logout">Logout</button>
                  </mat-menu>
                </div>
              </div>
            
          </div>
        </header>
