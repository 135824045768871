import { Injectable } from '@angular/core';
import { HttpHeaders, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()

export class HeaderInterceptorsService {
  defaultHeaders :{};
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.defaultHeaders = {
      'Content-Type': 'application/json; charset=UTF-8'
    };
    // const authToken = 'dahskahdkkdksdhkhsdk';
    // if (authToken) {
    //   defaultHeaders['Authorization'] = 'Bearer ' + authToken;
    // }
    //window.console.log(req.url);
    // if(req.url.indexOf("Login") > 0)
    // {
    //   this.defaultHeaders = {
    //     'Content-Type': 'application/json; charset=UTF-8'
    //   };
    // }
    if (req.headers.keys().length > 0) {   // This replaces default headers if any headers passed at the request time
      req.headers.keys().forEach((headerKey) => {
        if (headerKey === 'Content-Type' && req.headers.get(headerKey) === 'multipart/form-data') {
          delete this.defaultHeaders['Content-Type'];
        } else {
          this.defaultHeaders[headerKey] = req.headers.get(headerKey);
        }
      });
    }

    const modifiedReq = req.clone({
      headers: new HttpHeaders(this.defaultHeaders),
    });
    return next.handle(modifiedReq);
  }
}
