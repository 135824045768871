import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from "rxjs";
import { environment } from '../../../../../environments/environment';
import 'rxjs/add/observable/of';
@Injectable({
  providedIn: 'root'
})
export class BusTicketDetailsService {
  public editVal: boolean;
  public submit: boolean;
  private REST_API_SERVER = `${environment.BASE_URL}/api/Bus/GetBusTicketDetails`;
  private Update_Category = `${environment.BASE_URL}/api/Account/SaveCategory`
  private getInvoice = `${environment.BASE_URL}/api/Bus/GetBusInvoice`;
  private cancelTicket_API = `${environment.BASE_URL}/api/Bus/CancelTicket`;
  private cancelConfirmationTicket_API = `${environment.BASE_URL}/api/Bus/CancelConfirmTicket`;

  constructor(private httpClient: HttpClient) { }

  getBusTicket(body){
      return this.httpClient.post(this.REST_API_SERVER, body);
  }

  getBusInvoice(body){
    return this.httpClient.post(this.getInvoice, body);
}

cancelTicket(body){
  return this.httpClient.post(this.cancelTicket_API, body);
}

cancelConfirmationTicket(body){
  return this.httpClient.post(this.cancelConfirmationTicket_API, body);
}

}