import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from 'src/app/model/user.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private REST_API_LOGIN = `${environment.BASE_URL}/api/Account/Login`;
    private Forgot_Password = `${environment.BASE_URL}/api/Account/ForGotPassword`;
    isAgent:boolean=false;
    constructor(private http: HttpClient,private httpClient: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string,agentLogin:boolean) {
        let loginDetails = {
            "Email": email,
            "Password": password,
            "DeviceId": '152.44.48.89',//this.ipAddress,
            "IsAgentLogin": agentLogin
            }

            // .subscribe((res:any)=>{
            //     return this.http.post<any>(`${config.apiUrl}/users/authenticate`, { username, password })
        return this.httpClient.post<any>(this.REST_API_LOGIN,loginDetails)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem ('token', user.Token);
                    var userData = {
                       "parentId": user.UserDetails.ParentId,
                       "categoryId": user.UserDetails.CategoryId,
                       "role": user.UserDetails.Role,
                       "firstName": user.UserDetails.UserAdditionalDetails.FirstName,
                       "lastName": user.UserDetails.UserAdditionalDetails.LastName,
                       "balance": user.UserDetails.UserBalance.Balance,
                       "userId":  user.UserDetails.UserServices[0].UserId,
                       "email":email,
                       "phone":user.UserDetails.Mobile,
                       "Code": user.UserDetails.Code,
                       "CompanyName": user.UserDetails.UserAdditionalDetails.CompanyName,
                       "userAdditionalDetails":user.UserDetails.UserAdditionalDetails
                    }
                        let roleEnablement = user.SecreenConfiguration;
                        localStorage.setItem('roles', JSON.stringify(roleEnablement));
                        localStorage.setItem('userInfo', JSON.stringify(userData));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('roles');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('token');
        this.currentUserSubject.next(null);
    }
}
