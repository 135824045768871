import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from "@angular/core"
import { isUndefined } from 'util'
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/public_api'
import { Observable, of, Observer } from 'rxjs'
import { CommonRequestService } from 'src/app/shared/services/http/common-request.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Component({
  selector: "location-picker",
  templateUrl: "./location-picker.component.html",
  encapsulation: ViewEncapsulation.None
  //styleUrls: ['./people-picker.component.css'],
})
export class LocationPickerComponent implements OnInit {

  constructor(
    private _commonRequestService: CommonRequestService,
    private httpClient: HttpClient
  ) {
    this.dataSource = Observable.create((observer: Observer<any[]>) => {
      this.getLocListBySearch(this.selectedValue, observer)
     });

  }

  ngOnInit() {
    // this.getLocList();

  }

  @Input() selectedLocation: any;

  @Input() fiscalYear: any;
  @Input() personType: any;
  @Input() excludeUserIds: any;
  @Input() selectedPerson: any;
  @Input() placeHolder:string;

  @Output() onSelectLocationChange = new EventEmitter<any>();
  @Output() onPeoplePickerChange = new EventEmitter<any>();

  noResult: boolean = false;
  isLoading: boolean = false;
  selectedValue: string;
  dataSource: Observable<any>;
  finalList: any = [];
  cityList: any = [];
  private SearchCity = `${environment.BASE_URL}/api/Bus/SearchCity`;

  private getLocList() {
    const requestObj = {
      type: 'GET',
      path: '/api/Bus/GetCityList'
    };
    this._commonRequestService.request(requestObj, null, null, null).subscribe((res) => {
      //console.log(res);
      this.cityList = res;
      localStorage.busList = JSON.stringify(res);
    });
  }

  getLocListBySearch(searchTerm: string, observer: Observer<any[]>) {
    const body = {
      Keyword: searchTerm
  
    };
   //var searchpersonType = this.personType === undefined ? this._enums.personType.OnlyPPMDs : this.personType;

   var locList ;
   this.httpClient.get(this.SearchCity, {params:body}).subscribe((data) => {
          locList =data;

     this.finalList = locList;

     observer.next(locList);
   }, (errorData) => {

   });
  };

  onSelect(event: TypeaheadMatch): void {
    this.selectedValue=event.item.CityName;
    this.selectedLocation = event.item;
    this.onSelectLocationChange.emit(this.selectedLocation);
    this.onPeoplePickerChange.emit(this.selectedPerson);
  }
  onLoading(event: boolean): void {
    this.isLoading = event;
  }
  NoResults(event: boolean): void {
    this.noResult = event;
  }
  onModelChange(item: string) {
    this.selectedPerson = null;
    this.onSelectLocationChange.emit(this.selectedPerson);
  }
  reset() {
    this.selectedValue = '';
    this.selectedPerson = null;
    this.onSelectLocationChange.emit(this.selectedLocation);
    this.onPeoplePickerChange.emit(this.selectedPerson);
  }
}
