// Angular Moudles
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

// Custom Modules
import { PredefinedModule } from './modules/predefined/predefined.module';
import { CustomModule } from './modules/custom/custom.module';
import { AgentHeaderComponent } from './components/layouts/common/agent-header/agent-header.component';
import { HeaderComponent } from './components/layouts/common/header/header.component';
import { FooterComponent } from './components/layouts/common/footer/footer.component';
import { DynamicTemplateComponent } from './components/layouts/dynamicTemplate/dynamic-template.component';
import { ControlMessagesComponent } from './custom-validators/control-messages.component';
import { DynamicTemplateParamComponent } from './components/layouts/dynamicTemplateWithParam/dynamic-template-param.component';



@NgModule({
  declarations: [
    HeaderComponent,
    AgentHeaderComponent,
    FooterComponent,
    DynamicTemplateComponent,
    ControlMessagesComponent,
    DynamicTemplateParamComponent
    

  ],
  imports: [
    CommonModule,
    CustomModule,
    PredefinedModule
  ],
  exports: [
    CustomModule,
    PredefinedModule,
    HeaderComponent,
    AgentHeaderComponent,
    FooterComponent,
    DynamicTemplateComponent,
    ControlMessagesComponent,
    DynamicTemplateParamComponent
  ]
})

// Import this Module in all submodules for reusablility
export class SharedModule { }
