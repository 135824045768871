<div class="searchContainer dropdown">
  <div class="selected-profile-pic">
    <!--<img *ngIf="selectedPerson?.photoUrl" [src]="selectedPerson?.photoUrl" />
    <strong *ngIf="!selectedPerson?.photoUrl && selectedPerson?.ABBR">{{selectedPerson?.ABBR}}</strong>-->
  </div>
  <input [ngClass]="{'loading':isLoading,'font-semibold': selectedPerson,'searchIcon':!selectedPerson}" class="form-control searchInput"
         type="text" name="textSearch" [(ngModel)]="selectedValue" (ngModelChange)="onModelChange($event)"
         [placeholder]="placeHolder" autocomplete="off"
         [customTypeahead]="dataSource"
         (typeaheadOnSelect)="onSelect($event)"
         typeaheadOptionsLimit=2000
         (typeaheadLoading)="onLoading($event)"
         (typeaheadNoResults)="NoResults($event)"
                  [typeaheadItemTemplate]="busList1"
          />

  <!--<input [(ngModel)]="selectedValue"
         [typeahead]="busList"
         typeaheadOptionField="CityName"
         [typeaheadItemTemplate]="busList1">-->

  <!--  [typeaheadWaitMs]="500"-->

  <div class="searchNoResults" *ngIf="noResult">
    No Results Found
  </div>
</div>

<ng-template #busList1 let-model="item" let-index="index">
  <div class="display-table">
    <div class="display-table-cell searchUserProfilePicDiv">
      <!--<img [src]="model.photoUrl" *ngIf="model?.photoUrl" class="searchUserProfilePic" />-->
<!--      <strong *ngIf="!model?.photoUrl && model?.ABBR">{{model.ABBR}}</strong>-->
    </div>
    <div class="display-table-cell">
      <span class="name">{{model.CityName}} </span>
    </div>
  </div>
</ng-template>
