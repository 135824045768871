<div>
  <h1 matDialogTitle>{{ data.title }}</h1>
  <div mat-dialog-content>{{ data.message }}</div>
  <div mat-dialog-actions>
    <span fxFlex></span>
    <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(true)">
      {{data.okButton }}
    </button>
  </div>
</div>
