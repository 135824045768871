

<div>
  <main id="content">
    <div class="container-fuild">
      <agent-header *ngIf="!router.url.includes('app-dynamic')"></agent-header>
    </div>
      <div class="container space-2 space-top-xl-2">
        <router-outlet></router-outlet>
      </div>
  </main>
</div>
