// Angular Moudles
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

// custom components
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
//import { LocationPickerComponent } from './shared/directives/locationPicker/location-picker.component'
//import { SeatLayoutComponent } from './shared/directives/seatLayout/seat-layout.component'

// Custom Modules
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptorsService } from './shared/services/http/header-interceptors.service';

import { environment } from './../environments/environment';
import { HomeComponent } from './views/admin-views/home/home.component';

import { TopHeaderComponent } from '../app/shared/components/layouts/common/top-header/top-header.component';

import { HttpClientModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { DataService } from './shared/services/data.service';
import { Ng5SliderModule } from 'ng5-slider';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { AuthenticationService } from './shared/services/authentication.service';
import { LoginNewComponent } from './views/loginnew/loginnew.component';
import { AboutusComponent } from './views/aboutus/aboutus.component';
import { ContactusComponent } from './views/contactus/contactus.component';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';


@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    AdminLayoutComponent,
    TopHeaderComponent, 
    HomeComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    TypeaheadModule.forRoot(),
    Ng5SliderModule,
    FormsModule,
    ReactiveFormsModule,ScrollingModule,CdkScrollableModule

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptorsService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    {provide : LocationStrategy, useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthenticationService,
    DataService
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
