import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  dashBoardValue: boolean = true;
  private dashBoardValue$ = new BehaviorSubject(this.dashBoardValue);
  private REST_API_SERVER = `${environment.BASE_URL}/api/Account/GetUserBalance`;
  constructor(private httpClient: HttpClient) { }
  
  setValue(value){
    this.dashBoardValue$.next(value);
  }
  getValue(){
    return this.dashBoardValue$.asObservable();
  }

  Refresh() {
     return this.httpClient.get(this.REST_API_SERVER);
  }

}
