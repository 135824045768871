import { Injectable } from '@angular/core';
import { CommonHttpService } from './common-http.service';
import { FrameURLService } from './frame-url.service';
import { Observable } from 'rxjs';
import Utils from '../common/utils';

@Injectable({
  providedIn: 'root'
})
export class CommonRequestService {

  constructor(private _frameUrlService: FrameURLService,
    private _commonHttpService: CommonHttpService) { }

  request(requestObject, postBody = null, httpParams: null, customHeaders = null): Observable<any> {
    return this.mainRequest(Utils.avoidShallowClone(requestObject), this.shiftDates(postBody), httpParams, customHeaders);
  }
  mainRequest(requestObject, postBody = null, httpParams: null, customHeaders = null): Observable<any> {
    requestObject.path = this._frameUrlService.getHttpFramedURL(requestObject);
    return this._commonHttpService.sendReciveService(requestObject, postBody, httpParams, customHeaders);
  }
  shiftDates(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date) {
        body[key] = value.toLocaleString();
      } else if (typeof value === 'object') {
        body[key]=this.shiftDates(value);
      }
    }
    return body;
  }
}
