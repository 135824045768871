import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from "rxjs";
import { environment } from '../../../../../environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable({
  providedIn: 'root'
})
export class AgentListService {
  agentListValue: boolean = false;
  data:any;
  private agentListValue$ = new BehaviorSubject(this.agentListValue);
  private agentData$ = new BehaviorSubject(this.data);
  private REST_API_SERVER = `${environment.BASE_URL}/api/Account/GetUsers`;
  private Save_User_Status = `${environment.BASE_URL}/api/Account/UpdateUserStatus`;

constructor(private httpClient: HttpClient) { }

setValue(value){
    this.agentListValue$.next(value);
  }
  getValue(){
    return this.agentListValue$.asObservable();
  }
  setData(value){
    this.agentData$.next(value);
  }
  getData(){
    return this.agentData$.asObservable();
  }

public getAgentList(body){
     return this.httpClient.post(this.REST_API_SERVER, body);
 }

 public updateAgent(body){
   return this.httpClient.put(this.Save_User_Status,body)
 }

}