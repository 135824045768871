import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'control-messages',
  template: `<div class="invalid-feedback  d-block" *ngIf="errorMessage !== null">{{ errorMessage }}</div>`
})
export class ControlMessagesComponent {
  private readonly errorMessages = {
    'required': 'Required',
  };
  
  @Input() control: FormControl;
  @Input() submitted: boolean ;
  @Input() controlName: string ;
  
  get errorMessage(): string {
    for (let propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.submitted) {
        return this.controlName + ' is ' + this.errorMessages[propertyName];
      }
    }
    return null;
  }
}
