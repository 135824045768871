import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef,OnChanges, SimpleChanges,OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';

import { EventEmitterService } from '../../services/event/event-emitter.service';
import { Observable, Subject } from 'rxjs';
import { Options,LabelType } from 'ng5-slider';

@Component({
  selector: 'app-search',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search.component.html',
  styleUrls: ['search.component.scss'],
  styles: [
    `
      .form-control {
        margin-bottom: 15px;
      }
    `
  ]
})
export class SearchComponent implements OnInit,OnChanges,OnDestroy {
  form: FormGroup;
  levels = [
    "Beginner",
    "Expert",
  ];
  value: number = 100;
  minValue: number = 100;
  maxValue: number = 400;
  options: Options = {
    floor: 0,
    ceil: 200
  };

  panelOpenState = true;

  @Input() filterInputList: any;
  _data:any;

  @Output() groupFilters: EventEmitter<any> = new EventEmitter<any>();
  searchText: string = '';
  constructor(private fb: FormBuilder,
    private eventEmitterService: EventEmitterService,
    private cd: ChangeDetectorRef
  ) {

    //this.addCheckboxes();
  }

 
  ngOnInit(): void {


  // });
   
  this.activate();
  
    if (this.eventEmitterService.subsVar === undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeResetFilter.subscribe(() => {
          this.resetFilterFunc();
        });
    }


    //this.buildForm();
  }


  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'filterInputList': {
              this.activate();
          }
        }
  }
}
  }
  activate() {
  

    this._data = this.filterInputList;     

    if(this._data){
    this.form=this.fb.group({});
    
    this.buildForm();
    this.addCheckboxes();
    }

    
   }

  buildForm(): void {
    this.form = this.fb.group({
      boardingPoints: new FormArray([]),
      operators: new FormArray([]),
      busTypes: new FormArray([]),
      depBefore6AM: new FormControl(''),
      //lastName: new FormControl(''),
      //jobTitle: new FormControl(''),
      //level: new FormControl(''),
      //agefrom: new FormControl(''),
      //ageto: new FormControl(''),

      //BoardingPoints: new FormControl(''),
      droppingPoint: new FormControl(''),
      depBetween6AMAnd12PM: false,
      depBetween12PMAnd6PM: false,
      depAfter6PM: false,

      busTypeSeater: false,
      busTypeSleeper: false,
      busTypeAC: false,
      busTypeNonAc: false,

      arrBefore6AM: false,
      arrBetween6AMAnd12PM: false,
      arrBetween12PMAnd6PM: false,
      arrAfter6AM: false,
      sliderControl:new FormControl([this._data.minPrice, this._data.maxPrice])
    })

  }

  search(filters: any): void {
    //Object.keys(filters).forEach(key => filters[key] === '' ? delete filters[key] : key);
    //filters.forEach(
    //  key => {
    //    key === 'orders' ? this.getCheckboxValue(0) : key
    //  }
    //);

    Object.keys(filters).forEach(
      key => {
        key == 'boardingPoints' ? filters[key] = this.getCheckboxValue(filters[key],'boardingPoints') :
        key == 'operators' ? filters[key] = this.getCheckboxValue(filters[key],'operators') :
        key == 'busTypes' ? filters[key] = this.getCheckboxValue(filters[key],'busTypes') :
        filters[key] === '' ? delete filters[key] : key
      }
    );

    this.groupFilters.emit(filters);
  }

  private addCheckboxes() {
    if(this._data.boardingPointsList){
    this._data.boardingPointsList.forEach((o, i) => {
      const control = new FormControl(); // if first item set to true, else false
      (this.form.controls.boardingPoints as FormArray).push(control);
    });
  }
  if(this._data.busTypeList){
    this._data.busTypeList.forEach((o, i) => {
      const control = new FormControl(); // if first item set to true, else false
      (this.form.controls.busTypes as FormArray).push(control);
    });
  }
  if(this._data.operatorList){
    this._data.operatorList.forEach((o, i) => {
      const control = new FormControl(); // if first item set to true, else false
      (this.form.controls.operators as FormArray).push(control);
    });
  }

  }

  private getCheckboxValue(list,type) {
    var final = [];
    if(type == 'boardingPoints'){
      if(this._data.boardingPointsList){
      this._data.boardingPointsList.forEach((o, i) => {
        list.forEach((x, idx) => {
          if (i == idx && x == true) {
            final.push(o);
          }
        })
      });
    }
  }
    else if(type =='busTypes'){
      if(this._data.busTypeList){
      this._data.busTypeList.forEach((o, i) => {
        list.forEach((x, idx) => {
          if (i == idx && x == true) {
            final.push(o);
          }
        })
      });
    }
  }
    else if(type =='operators'){
      if(this._data.operatorList){
      this._data.operatorList.forEach((o, i) => {
        list.forEach((x, idx) => {
          if (i == idx && x == true) {
            final.push(o);
          }
        })
      });
    }
  }

    return final;
  }


  private resetFilterFunc() {
    this.activate();
    // if(this.resetFilter == true)
    // this.ngOnInit();

  }

  private reset()
  {
    this.form.reset({sliderControl: [0,0]});
  }

  high(val){

    var v = val;
  }


  ngOnDestroy() {
    this.eventEmitterService.subsVar.unsubscribe()
    }

  
}
