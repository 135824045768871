import { Component, OnInit, Input, ChangeDetectorRef, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonRequestService } from 'src/app/shared/services/http/common-request.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import Utils from 'src/app/shared/services/common/utils';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from 'src/app/shared/modal/modal.component'
import { EventEmitterService } from 'src/app/shared/services/event/event-emitter.service';
import { DataService } from 'src/app/shared/services/data.service';
import { BehaviorSubject } from 'rxjs';
import { Options } from 'ng5-slider';
import { AgeValidator } from 'src/app/shared/custom-validators/age.validator';
import { DatePipe } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';
import { LocationPickerComponent } from 'src/app/shared/directives/locationPicker/location-picker.component';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';


@Component({
  selector: 'app-buses',
  templateUrl: './buses.component.html',
  styleUrls: ['./buses.component.scss']
})
export class BusesComponent implements OnInit {

  @ViewChild('source')
  source: LocationPickerComponent;

  @ViewChild('destination')
  destination: LocationPickerComponent;

  user: any;
  date1 = new FormControl(new Date());
  today = new Date();
  date = new Date((new Date().getTime() - 3888000000));
  searchParams: any = {
    TraceId: '',
    SourceId: '',
    DestinationId: '',
    JourneyDate: new Date()

  };
  busesSearchResponse: any = {
    AvailableBuses: [],
    Status: {}
  };
  clonedBusSearchResponse: any = [];


  filteredAvailableBuses: any[] = [];

  //busList: any = [];
  busForm: FormGroup;
  modify: boolean;
  arrivalTimeList: any = [];
  departureTimeList: any = [];
  boardingList: any = [];
  seatLayout;
  EzeeInfoTripCode;
  EzeeInfoTripStageCode;

  depBefore6AM: boolean;
  depBetween6AMAnd12PM: boolean;
  depBetween12PMAnd6PM: boolean;
  depAfter6PM: boolean;

  busTypeSeater: boolean;
  busTypeSleeper: boolean;
  busTypeAC: boolean;
  busTypeNonAc: boolean;

  arrBefore6AM: boolean;
  arrBetween6AMAnd12PM: boolean;
  arrBetween12PMAnd6PM: boolean;
  arrAfter6AM: boolean;

  travelFrom: string = "Travel From";
  travelTo: string = "Travel To";

  filterObj: any = {
    depBefore6AM: false,
    depBetween6AMAnd12PM: false,
    depBetween12PMAnd6PM: false,
    depAfter6PM: false,

    busTypeSeater: false,
    busTypeSleeper: false,
    busTypeAC: false,
    busTypeNonAc: false,

    arrBefore6AM: false,
    arrBetween6AMAnd12PM: false,
    arrBetween12PMAnd6PM: false,
    arrAfter6AM: false
  };

  filterDroppingTime: boolean = false;
  filterBoardingPoint: string = '';
  filterOperators: string = '';
  filterSlider: string = '';
  filterBusType: string = '';

  busFormFinal: any = {
    TraceId: '',
    SourceId: '',
    DestinationId: '',
    JourneyDate: ''
  };

  sourceCity: string = ""
  destCity: string = ""
  routeFromOtherPage = false;


  groupFilters: Object;
  @Input() searchByKeyword: string;

  seatLayoutLower = [];
  seatLayoutUpper = [];
  boardingPointsList = [];
  busTypeList = [];
  operatorList = [];
  fareList = [];
  searchBus: boolean = false;

  ticketTemplate: any;
  showWindow: boolean = false;
  filterFlag: boolean = false;
  finalFilterInputList: any;

  // -----------------------Bus registration Form
  busRegistrationForm: FormGroup;
  busRegistrationFormSubmitted = false;

  busformSubmitted = false;
  sourceIdValid = true;
  destIdValid = true;
  JourneyDateValid = true;

  busSearchForm: FormGroup;

  @ViewChild('elementToFocus') _input: ElementRef;

  // ---------------------
  constructor(
    private activatedRoute: ActivatedRoute,
    private commonRequestService: CommonRequestService,
    private formBuilder: FormBuilder,
    private location: Location,
    private router: Router,
    public dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private eventEmitterService: EventEmitterService,
    private dataService: DataService,
    private datePipe: DatePipe
  ) {

  }
  _openCalendar(picker: MatDatepicker<Date>) {
    picker.open();
    setTimeout(() => this._input.nativeElement.focus());
  }

  _closeCalendar(e) {
    setTimeout(() => this._input.nativeElement.blur());
  }
  exchageSourceAndDestination() {
    debugger;
    this.initializeBusForm();
    //this.source.selectedValue='';
    let a = this.searchParams.SourceId;
    let b = this.sourceCity;
    let c=this.source.selectedValue;

    this.searchParams.SourceId = this.searchParams.DestinationId;
    this.sourceCity = this.destCity;
    this.source.selectedValue=this.destination.selectedValue;

    
    this.searchParams.DestinationId = a;
    this.destCity = b;
    this.destination.selectedValue=c;
  }
  onChangeSeats(e) {
debugger;
    while (this.t.length !== 0) {
      this.t.removeAt(0)
    }
    const numberOfSeats = e.length || 0;
    if (this.t.length < numberOfSeats) {
      for (let i = this.t.length; i < numberOfSeats; i++) {
        this.t.push(this.formBuilder.group({
          passengerName: ['', Validators.required],
          passengerAge: ['', [Validators.required, AgeValidator]],
          Id: e[i].Id,
          passengerGender: ['M']
        }));
        // this.t.controls[i].controls.Id.setValue(e[i].Id);
      }
    } else {
      for (let i = this.t.length; i >= numberOfSeats; i--) {
        this.t.removeAt(i);
      }
    }
  }


  // convenience getters for easy access to form fields
  get f() { return this.busRegistrationForm.controls; }
  get t() { return this.f.passengerSeats as FormArray; }

  ngOnInit(): void {
    // this.today =this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.busRegistrationForm = this.formBuilder.group({
      passengerEmail: ['', [Validators.required, Validators.email]],
      passengerContact: ['', Validators.required],
      passengerAddress: ['testing'],
      passengerSeats: new FormArray([])

    });

    
    //  this.busSearchForm= this.formBuilder.group({


    //         StartDate: [new Date(), Validators.required],

    //       });

    this.user = JSON.parse(localStorage.getItem('userInfo'));
    // this.finalFilterInputList = new BehaviorSubject(this.filterInputList);

    this.initializeBusForm();
    //this.busFormSubmit();
    //this.initializeBusForm();
    //if (localStorage.busList) {
    //  this.busList = JSON.parse(localStorage.busList).slice(0, 100);
    //  if (this.busList.length === 0) {
    //    this.getBusList();
    //  }
    //} else {
    //  this.getBusList();
    //}

    this.activatedRoute.queryParams.subscribe(data => {
      let sourceId = data['sourceId'];
      let destId = data['destId'];
      let date = data['date'];
      this.searchParams.SourceId = sourceId;
      this.searchParams.DestinationId = destId;
      this.searchParams.JourneyDate = date;
      this.routeFromOtherPage = true;
    })


    //this.getBusList();

    //this.activatedRoute.queryParams.subscribe(params => {
    //  console.log(params);
    //  this.searchParams = JSON.parse(params.search);
    //  console.log(this.searchParams);
    //  if (localStorage.busSearch) {
    //    this.formDesiredResult(JSON.parse(localStorage.busSearch));
    //  }

    // if (this.searchParams.TraceId) {
    //   this.getBusSearch();
    //   this.busForm.get('SourceId').setValue(this.searchParams.SourceId);
    //   this.busForm.get('DestinationId').setValue(this.searchParams.DestinationId);
    //   this.busForm.get('JourneyDate').setValue(this.searchParams.JourneyDate);
    // }
    //});
    this.searchParams.JourneyDate=new Date();
  }


  searchFilter(data) {
    this.groupFilters = data;
    if (this.groupFilters) this.filterBusList(this.groupFilters, this.busesSearchResponse.AvailableBuses);
  }

  filterBusList(filters: any, users: any): void {
    var filteredBuses = this.busesSearchResponse.AvailableBuses;
    var filteredBuses1 = this.busesSearchResponse.AvailableBuses;
    var filteredBuses2 = this.busesSearchResponse.AvailableBuses;
    var filteredBuses3 = this.busesSearchResponse.AvailableBuses;
    var filteredBuses4 = this.busesSearchResponse.AvailableBuses;
    //this.filteredAvailableBuses = this.busesSearchResponse.AvailableBuses; //Reset User List

    const keys = Object.keys(filters);
    const filterBus = bus => {
      let result = keys.map(key => {
        if (!~key.indexOf('age')) {
          if (bus[key]) {
            return String(bus[key]).toLowerCase().startsWith(String(filters[key]).toLowerCase())
          } else {
            return false;
          }
        }

        //if (key.indexOf('BoardingPoints')) {
        //  if (bus['BoardingPoints'] ='BoardingPoints') {
        //    return String(bus[key]).toLowerCase().startsWith(String(filters[key]).toLowerCase())
        //  } else {
        //    return false;
        //  }
        //}


      });
      // To Clean Array from undefined if the age is passed so the map will fill the gap with (undefined)


      if (filters['depBefore6AM']) {
        if (bus['DepartureTime']) {
          if (this.getTwentyFourHour(bus['DepartureTime']) <= 6) {
            result.push(true);
          } else {
            result.push(false);
          }
        }
        else {
          result.push(false);
        }
        //console.log('depBefore6AM');
        //result.push(bus);
      }

      if (filters['depBetween6AMAnd12PM']) {
        if (bus['DepartureTime']) {
          if ((this.getTwentyFourHour(bus['DepartureTime']) > 6) && (this.getTwentyFourHour(bus['DepartureTime']) <= 12)) {
            result.push(true);
          } else {
            result.push(false);
          }
        }
        else {
          result.push(false);
        }
        //console.log('depBefore6AM');
        //result.push(bus);
      }

      if (filters['depBetween12PMAnd6PM']) {
        if (bus['DepartureTime']) {
          if ((this.getTwentyFourHour(bus['DepartureTime']) > 12) && (this.getTwentyFourHour(bus['DepartureTime']) <= 18)) {
            result.push(true);
          } else {
            result.push(false);
          }
        }
        else {
          result.push(false);
        }
        //console.log('depBefore6AM');
        //result.push(bus);
      }


      if (filters['depAfter6PM']) {
        if (bus['DepartureTime']) {
          if (this.getTwentyFourHour(bus['DepartureTime']) > 18) {
            result.push(true);
          } else {
            result.push(false);
          }
        }
        else {
          result.push(false);
        }
        //console.log('depBefore6AM');
        //result.push(bus);
      }



      var v = result.reduce((acc, cur: any) => { return acc || cur }, 1);
      var v1 = result.reduce((acc, cur: any) => { return acc || cur }, 0);
      return result.reduce((acc, cur: any) => { return acc || cur }, 0)
    }


    this.filterDroppingTime = this.allTrue(filters);

    if (this.filterDroppingTime) {
      filteredBuses = this.busesSearchResponse.AvailableBuses.filter(filterBus);
    }
    else {
      filteredBuses = this.busesSearchResponse.AvailableBuses;
    }




    const filterBus1 = bus => {
      let result = keys.map(key => {
        if (!~key.indexOf('age')) {
          if (bus[key]) {
            return String(bus[key]).toLowerCase().startsWith(String(filters[key]).toLowerCase())
          } else {
            return false;
          }
        }



      });
      // To Clean Array from undefined if the age is passed so the map will fill the gap with (undefined)
      result = result.filter(it => it !== undefined);
      // Filter the Age out from the other filters
      if (filters['boardingPoints']) {
        var tempFlag = false;
        bus.BoardingPoints.forEach(value => {

          //if (value.Location.toLowerCase().includes(filters['BoardingPoints'].toLowerCase())) {
          //  result.push(true);
          //}
          filters['boardingPoints'].forEach(innerValue => {
            if (value.Id == innerValue.Id) {
              tempFlag = true;
            }
          })

        });
        if (tempFlag)
          result.push(true);

      }


      var v = result.reduce((acc, cur: any) => { return acc || cur }, 1);
      var v1 = result.reduce((acc, cur: any) => { return acc || cur }, 0);
      //let v = result.filter{ $0 == true}.count> 0;
      return result.reduce((acc, cur: any) => { return acc || cur }, 0)
    }

    if (filters['boardingPoints']) {
      this.filterBoardingPoint = filters['boardingPoints'];
    }
    else {
      this.filterBoardingPoint = '';
    }
    if (this.filterBoardingPoint != '') {
      filteredBuses1 = filteredBuses.filter(filterBus1);
    }
    else {
      filteredBuses1 = filteredBuses;
    }


    const filterBus2 = bus => {
      let result = keys.map(key => {
        if (!~key.indexOf('age')) {
          if (bus[key]) {
            return String(bus[key]).toLowerCase().startsWith(String(filters[key]).toLowerCase())
          } else {
            return false;
          }
        }



      });
      // To Clean Array from undefined if the age is passed so the map will fill the gap with (undefined)
      result = result.filter(it => it !== undefined);
      // Filter the Age out from the other filters
      if (filters['operators']) {

        filters['operators'].forEach(innerValue => {
          if (bus.OperatorId == innerValue.OperatorId) {
            result.push(true);
          }
        })

      }


      var v = result.reduce((acc, cur: any) => { return acc || cur }, 1);
      var v1 = result.reduce((acc, cur: any) => { return acc || cur }, 0);
      //let v = result.filter{ $0 == true}.count> 0;
      return result.reduce((acc, cur: any) => { return acc || cur }, 0)
    }

    if (filters['operators']) {
      this.filterOperators = filters['operators'];
    }
    else {
      this.filterOperators = '';
    }
    if (this.filterOperators != '') {
      filteredBuses2 = filteredBuses1.filter(filterBus2);
    }
    else {
      filteredBuses2 = filteredBuses1;
    }
    this.filteredAvailableBuses = filteredBuses2;


    const filterBus3 = bus => {
      let result = keys.map(key => {
        if (!~key.indexOf('age')) {
          if (bus[key]) {
            return String(bus[key]).toLowerCase().startsWith(String(filters[key]).toLowerCase())
          } else {
            return false;
          }
        }



      });
      // To Clean Array from undefined if the age is passed so the map will fill the gap with (undefined)
      result = result.filter(it => it !== undefined);
      // Filter the Age out from the other filters
      if (filters['sliderControl']) {
        bus.Fare.split(',').forEach(fare => {
          if (fare >= filters['sliderControl'][0] && fare <= filters['sliderControl'][1])
            result.push(true);

        })

      }

      var v = result.reduce((acc, cur: any) => { return acc || cur }, 1);
      var v1 = result.reduce((acc, cur: any) => { return acc || cur }, 0);
      //let v = result.filter{ $0 == true}.count> 0;
      return result.reduce((acc, cur: any) => { return acc || cur }, 0)
    }

    if (filters['sliderControl']) {
      this.filterSlider = filters['sliderControl'];
    }
    else {
      this.filterSlider = '';
    }
    if (this.filterSlider != '') {
      filteredBuses3 = filteredBuses2.filter(filterBus3);
    }
    else {
      filteredBuses3 = filteredBuses2;
    }
    this.filteredAvailableBuses = filteredBuses3;

    const filterBus4 = bus => {
      let result = keys.map(key => {
        if (!~key.indexOf('age')) {
          if (bus[key]) {
            return String(bus[key]).toLowerCase().startsWith(String(filters[key]).toLowerCase())
          } else {
            return false;
          }
        }



      });
      // To Clean Array from undefined if the age is passed so the map will fill the gap with (undefined)
      result = result.filter(it => it !== undefined);
      // Filter the Age out from the other filters
      if (filters['busTypes']) {

        filters['busTypes'].forEach(innerValue => {
          if (bus.OperatorId == innerValue.OperatorId) {
            result.push(true);
          }
        })

      }


      var v = result.reduce((acc, cur: any) => { return acc || cur }, 1);
      var v1 = result.reduce((acc, cur: any) => { return acc || cur }, 0);

      return result.reduce((acc, cur: any) => { return acc || cur }, 0)
    }

    if (filters['busTypes']) {
      this.filterBusType = filters['busTypes'];
    }
    else {
      this.filterBusType = '';
    }
    if (this.filterBusType != '') {
      filteredBuses4 = filteredBuses3.filter(filterBus4);
    }
    else {
      filteredBuses4 = filteredBuses3;
    }
    this.filteredAvailableBuses = filteredBuses4;


    this.loadUsers();



  }

  allTrue(obj) {
    for (var o in obj)
      if (typeof obj[o] === 'boolean' && obj[o]) return true;

    return false;
  }

  loadUsers(): void {
    //this.filteredAvailableBuses = 
    if (Boolean(this.filterDroppingTime) == true || this.filterBoardingPoint != '' || this.filterOperators != '' || this.filterBusType != '' || this.filterSlider != '') {
      this.filteredAvailableBuses = this.filteredAvailableBuses;
    }
    else {
      this.filteredAvailableBuses = this.busesSearchResponse.AvailableBuses;
    }
  }

  private initializeBusForm() {
    this.searchBus = false;
    this.eventEmitterService.onResetFilter();

    this.busesSearchResponse = {
      AvailableBuses: [],
      Status: {}
    };

    if (!this.routeFromOtherPage) {
      this.searchParams = {
        TraceId: '',
        SourceId: '',
        DestinationId: '',
        JourneyDate: new Date()

      };
    }
    // this.loadUsers();
    //this.busForm = this.formBuilder.group({
    //  TraceId: ['123'],
    //  SourceId: ['', Validators.compose([Validators.required])],
    //  DestinationId: ['', Validators.compose([Validators.required])],
    //  JourneyDate: [new Date().toISOString().slice(0, 16), Validators.compose([Validators.required])]
    //});
  }

  // convenience getter for easy access to form fields
  get busFormControls() { return this.busForm.controls; }

  busFormSubmit() {
    this.busformSubmitted = true;

    let formValid = this.validationCheck();
    if (!formValid) { return false; }
    this.getBusList();


  }

  validationCheck() {

    if (this.searchParams.SourceId == undefined || this.searchParams.SourceId == '') {
      this.sourceIdValid = false; return false
    }
    else { this.sourceIdValid = true }
    if (this.searchParams.DestinationId == undefined || this.searchParams.DestinationId == '') {
      this.destIdValid = false; return false
    }
    else { this.destIdValid = true; }
    if (this.searchParams.JourneyDate == undefined || this.searchParams.JourneyDate == '') {
      this.JourneyDateValid = false; return false
    } else { this.JourneyDateValid = true }
    return true;
  }

  private getBusList() {
    //this.searchParams.SourceId = 6558;
    //this.searchParams.DestinationId = 1584;
    //this.searchParams.JourneyDate = "2020-05-25t08:51:19.358z";
    //this.sourceCity = "Hyderabad";
    //this.destCity = "Bangalore";

    //var obj =
    //{
    //  SourceId: "6558",
    //  DestinationId: "19684",
    //  JourneyDate: "2020-06-30T08:51:19.358Z",
    //  UserType: 4,
    //  UserId: 4
    //}

    var obj = {
      SourceId: this.searchParams.SourceId,
      DestinationId: this.searchParams.DestinationId,
      JourneyDate: (this.searchParams.JourneyDate),
      UserType: this.user.role,
      UserId: this.user.userId
    }

    const requestObj = {
      type: 'POST',
      path: '/api/Bus/Search'
    };

    this.commonRequestService.request(requestObj, obj, null, null).subscribe((res) => {
      //console.log(res);

      if (res) {

        this.initializeBusForm();
        this.searchBus = true;
        //this.busesSearchResponse = res;
        if (res.AvailableBuses) {
          this.formDesiredResult(res);
        }
      }

    });


    //const requestObj = {
    //  type: 'GET',
    //  path: '/api/Bus/GetCityList'
    //};
    //this.commonRequestService.request(requestObj, null, null, null).subscribe((res) => {
    //  console.log(res);
    //  this.busList = res;
    //  localStorage.busList = JSON.stringify(res);
    //});
  }

  private getBusSearch() {
    const requestObj = {
      type: 'POST',
      path: '/api/Bus/Search'
    };
    this.commonRequestService.request(requestObj, this.searchParams, null, null).subscribe((res) => {
      this.formDesiredResult(res);
    });
  }

  formDesiredResult(data: any) {
    // this.eventEmitterService.onResetFilter();
    this.filterDroppingTime = false;
    this.filterBoardingPoint = '';
    this.filterOperators = ''
    this.filterSlider = ''
    this.filterBusType = ''
    var tempBoardingList = [];
    this.operatorList = [];
    this.fareList = [];
    this.boardingPointsList = [];
    this.busTypeList = [];
    
    // this.finalFilterInputList=[];
    // this.filterFlag =false;

    data.AvailableBuses.map((bus: any) => {
      // console.log(bus);
      bus.isShow = false;
      bus.showBoarding = false;
      bus.showBookingPolicies = false;
      bus.showSeats = false;
      bus.cancellationPolicy = JSON.parse(bus.CancellationPolicy);
      this.arrivalTimeList.push(bus.ArrivalTime);
      this.departureTimeList.push(bus.DepartureTime);
      this.boardingPointsList = [...this.boardingPointsList, ...bus.BoardingPoints];
      this.busTypeList.push({ OperatorId: bus.OperatorId, busType: bus.BusType });
      bus.payment = true;
      this.operatorList.push({ OperatorId: bus.OperatorId, OperatorName: bus.OperatorName });
      this.fareList = [...this.fareList, ...bus.Fare.split(',')];
      this.EzeeInfoTripCode = bus.EzeeInfoTripCode;
      this.EzeeInfoTripStageCode = bus.EzeeInfoStageCode;
    });
    // this.boardingPointsList = Array.from(new Set(tempBoardingList.map(s => s.Id))).map(Id => { return { Id: Id, Location: tempBoardingList.find(s => s.Id === Id).Location };})
    // this.operatorList = Array.from(new Set(tempBoardingList.map(s => s.Id))).map(Id => { return { Id: Id, Location: tempBoardingList.find(s => s.Id === Id).Location };})


    this.clonedBusSearchResponse = Utils.avoidShallowClone(data);
    this.busesSearchResponse = data;
    this.arrivalTimeList = Array.from(new Set(this.arrivalTimeList));
    this.departureTimeList = Array.from(new Set(this.departureTimeList));
    this.fareList = Array.from(new Set(this.fareList));

    this.boardingPointsList = Array.from(new Set(this.boardingPointsList.map(b => b.Id))).map(Id => {
      return {
        Id,
        Location: this.boardingPointsList.find(b => b.Id === Id).Location,
        Time: this.boardingPointsList.find(b => b.Id === Id).Time,
      };
    });
    this.busTypeList = this.busTypeList;
    this.operatorList = Array.from(new Set(this.operatorList.map(b => b.OperatorId))).map(OperatorId => {
      return {
        OperatorId,
        OperatorName: this.operatorList.find(b => b.OperatorId === OperatorId).OperatorName
      };
    });

    let filterInputList = {
      boardingPointsList: [],
      busTypeList: [],
      operatorList: [],
      minPrice: 0,
      maxPrice: 0,
      options: {},
      value: 0
    };

    filterInputList.boardingPointsList = this.boardingPointsList;
    filterInputList.operatorList = this.operatorList;
    filterInputList.busTypeList = this.busTypeList;
    if (this.fareList.length > 0) {
      filterInputList.minPrice = this.fareList.reduce((a, b) => Math.min(a, b));
      filterInputList.maxPrice = this.fareList.reduce((a, b) => Math.max(a, b));
    }

    let opts: Options = {
      floor: filterInputList.minPrice,
      ceil: filterInputList.maxPrice,
    }

    filterInputList.options = opts;


    this.finalFilterInputList = filterInputList;
    // this.filterFlag =true;
    this.loadUsers();
  }

  showBoarding(i: any) {
    this.filteredAvailableBuses.map((bus: any, index: any) => {
      if (i === index) {
        if (bus.isShow && bus.showBoarding) {
          bus.isShow = false;
          bus.showBoarding = false;
          bus.showBookingPolicies = false;
          bus.showSeats = false;
        } else {
          bus.isShow = true;
          bus.showBoarding = true;
          bus.showBookingPolicies = false;
          bus.showSeats = false;
        }
      }
    });
  }

  showBookingPolicies(i: any) {
    this.filteredAvailableBuses.map((bus: any, index: any) => {
      if (i === index) {
        if (bus.isShow && bus.showBookingPolicies) {
          bus.isShow = false;
          bus.showBoarding = false;
          bus.showBookingPolicies = false;
          bus.showSeats = false;
        } else {
          bus.isShow = true;
          bus.showBoarding = false;
          bus.showBookingPolicies = true;
          bus.showSeats = false;
        }
      }
    });
  }

  showSeats(i: any) {
    this.filteredAvailableBuses.map((bus: any, index: any) => {
      if (i === index) {
        if (bus.isShow && bus.showSeats) {
          bus.isShow = false;
          bus.showBoarding = false;
          bus.showSeats = false;
          bus.showBookingPolicies = false;
          bus.showSeats = false;
        } else {
          bus.isShow = true;
          bus.showBoarding = false;
          bus.showBookingPolicies = false;
          bus.showSeats = true;
        }
      }
      else {
        bus.isShow = false;
        bus.showBoarding = false;
        bus.showSeats = false;
        bus.showBookingPolicies = false;
        bus.showSeats = false;

      }
    });
  }

  duration(start, end) {
    start = this.getTwentyFourHourTime(start);
    end = this.getTwentyFourHourTime(end);
    start = start.split(':');
    end = end.split(':');
    const startDate = new Date(0, 0, 0, start[0], start[1], 0);
    const endDate = new Date(0, 0, 0, end[0], end[1], 0);
    let diff = endDate.getTime() - startDate.getTime();
    let hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0) {
      hours = hours + 24;
    }
    return (hours <= 9 ? '0' : '') + hours + 'h' + ' ' + (minutes <= 9 ? '0' : '') + minutes + 'm';
  }

  getTwentyFourHourTime(amPmString) {
    const d = new Date('1/1/2013 ' + amPmString);
    return d.getHours() + ':' + d.getMinutes();
  }

  getTwentyFourHour(amPmString) {
    const d = new Date('1/1/2013 ' + amPmString);
    return Number(d.getHours() + '.' + d.getMinutes());
  }

  callCommonFilter(event?: any, key?: any) {
    //console.log(event, key);
    this.filterObj[`${key}`] = event.checked;
    //console.log(this.filterObj);
    /**
     * Making it into original array and
     * applying filters back
     */
    // this.busesSearchResponse.AvailableBuses = [...this.clonedBusSearchResponse.AvailableBuses];
    this.busesSearchResponse.AvailableBuses = [];
    const busResp = [];

    this.clonedBusSearchResponse.AvailableBuses.map((bus: any) => {
      const filterObj = this.filterObj;
      if (filterObj.depBefore6AM && this.getTwentyFourHour(bus.DepartureTime) <= 6) {
        //console.log('depBefore6AM');
        busResp.push(bus);
      }
      if (filterObj.depBetween6AMAnd12PM && ((this.getTwentyFourHour(bus.DepartureTime) > 6) && (this.getTwentyFourHour(bus.DepartureTime) <= 12))) {
        //console.log('depBetween6AMAnd12PM');
        busResp.push(bus);
      }
      if (filterObj.depBetween12PMAnd6PM && ((this.getTwentyFourHour(bus.DepartureTime) > 12) && (this.getTwentyFourHour(bus.DepartureTime) <= 18))) {
        //console.log('depBetween6AMAnd12PM');
        busResp.push(bus);
      }
      if (filterObj.depAfter6PM && (this.getTwentyFourHour(bus.DepartureTime) > 18)) {
        console.log('depAfter6PM');
        busResp.push(bus);
      }
      if (filterObj.busTypeSeater && (bus.BusType.toLowerCase().includes('seater'))) {
        console.log('busTypeSeater');
        busResp.push(bus);
      }
      if (filterObj.busTypeSeater && (bus.BusType.toLowerCase().includes('seater'))) {
        console.log('seater');
        busResp.push(bus);
      }
      if (filterObj.busTypeSleeper && (bus.BusType.toLowerCase().includes('sleeper'))) {
        console.log('sleeper');
        busResp.push(bus);
      }
      if (filterObj.busTypeAC && (bus.BusType.toLowerCase().includes('ac') && !bus.BusType.toLowerCase().includes('non'))) {
        console.log('AC');
        busResp.push(bus);
      }
      if (filterObj.busTypeNonAc && (bus.BusType.toLowerCase().includes('ac') && bus.BusType.toLowerCase().includes('non'))) {
        console.log('NON AC');
        busResp.push(bus);
      }

      this.busesSearchResponse.AvailableBuses = Array.from(new Set(busResp.map(b => b.ServiceId))).map(ServiceId => {
        return {
          ServiceId: `${ServiceId}`,
          ArrivalTime: busResp.find(b => b.ServiceId === ServiceId).ArrivalTime,
          AvailableSeats: busResp.find(b => b.ServiceId === ServiceId).AvailableSeats,
          BoardingPoints: busResp.find(b => b.ServiceId === ServiceId).BoardingPoints,
          BusType: busResp.find(b => b.ServiceId === ServiceId).BusType,
          CancellationPolicy: busResp.find(b => b.ServiceId === ServiceId).CancellationPolicy,
          CommPct: busResp.find(b => b.ServiceId === ServiceId).CommPct,
          DepartureTime: busResp.find(b => b.ServiceId === ServiceId).DepartureTime,
          DroppingPoints: busResp.find(b => b.ServiceId === ServiceId).DroppingPoints,
          Fare: busResp.find(b => b.ServiceId === ServiceId).Fare,
          IdProofRequired: busResp.find(b => b.ServiceId === ServiceId).IdProofRequired,
          InventoryType: busResp.find(b => b.ServiceId === ServiceId).InventoryType,
          IsChildConcession: busResp.find(b => b.ServiceId === ServiceId).IsChildConcession,
          IsFareUpdateRequired: busResp.find(b => b.ServiceId === ServiceId).IsFareUpdateRequired,
          IsGetLayoutByBpdp: busResp.find(b => b.ServiceId === ServiceId).IsGetLayoutByBpdp,
          IsOpLogoRequired: busResp.find(b => b.ServiceId === ServiceId).IsOpLogoRequired,
          IsOpTicketTemplateRequired: busResp.find(b => b.ServiceId === ServiceId).IsOpTicketTemplateRequired,
          IsRtc: busResp.find(b => b.ServiceId === ServiceId).IsRtc,
          MTicketAllowed: busResp.find(b => b.ServiceId === ServiceId).MTicketAllowed,
          OperatorId: busResp.find(b => b.ServiceId === ServiceId).OperatorId,
          OperatorName: busResp.find(b => b.ServiceId === ServiceId).OperatorName,
          PartialCancellationAllowed: busResp.find(b => b.ServiceId === ServiceId).PartialCancellationAllowed,
          RouteScheduleId: busResp.find(b => b.ServiceId === ServiceId).RouteScheduleId,
          APIType: busResp.find(b => b.ServiceId === ServiceId).APIType,
        };
      });


      // if (this.arrBefore6AM) {
      //   return (this.getTwentyFourHour(bus.ArrivalTime) <= 6);
      // }
      // if (this.arrBetween6AMAnd12PM) {
      //   return ((this.getTwentyFourHour(bus.ArrivalTime) > 6) && (this.getTwentyFourHour(bus.ArrivalTime) <= 12));
      // }
      // if (this.arrBetween12PMAnd6PM) {
      //   return ((this.getTwentyFourHour(bus.ArrivalTime) > 12) && (this.getTwentyFourHour(bus.ArrivalTime) <= 18));
      // }
      // if (this.arrAfter6AM) {
      //   return (this.getTwentyFourHour(bus.ArrivalTime) > 18);
      // }
      // if (
      //   !this.depBefore6AM &&
      //   !this.depBetween6AMAnd12PM &&
      //   !this.depBetween12PMAnd6PM &&
      //   !this.depAfter6PM &&
      //   !this.busTypeSeater &&
      //   !this.busTypeSleeper &&
      //   !this.busTypeAC &&
      //   !this.busTypeNonAc
      // ) {
      //   console.log('nothing is true');
      //   return bus;
      // }
    });
  }

  callShowSeatsAPI(bus, i) {
    this.seatLayout = null;
    //console.log(`Hello` + JSON.stringify(bus));
    debugger;
    const formObj = {
      TraceId: this.busesSearchResponse.TraceId,
      InventoryType: bus.InventoryType,
      RouteScheduleId: bus.RouteScheduleId,
      SourceId: parseInt(this.searchParams.SourceId),
      DestinationId: parseInt(this.searchParams.DestinationId),
      JourneyDate: (this.searchParams.JourneyDate),
      UserType: this.user.role,
      UserId: this.user.userId,
      OperatorName: bus.OperatorName,
      OperatorId:bus.OperatorId,
      SupplierId: bus.SupplierId,
      APIType:bus.APIType,
      Tripid:bus.Tripid,
      Srcorder:bus.Srcorder,
      Dstorder:bus.Dstorder,
      ApiInputs:{   
        EzeeInfoTripCode: bus.EzeeInfoTripCode,
        EzeeInfoTripStageCode: bus.EzeeInfoTripStageCode
      }
    };
    //const formObj =    {
    //   TraceId: "0bdc9e86-f1b3-4b55-8656-48a80c141038",
    //   InventoryType: "0",
    //   RouteScheduleId: "25591",
    //   SourceId: 6558,
    //   DestinationId: 19684,
    //   JourneyDate: "2020-05-20T12:55:36.742Z",
    //   UserType: "Admin",
    //   UserId: "string"
    //}

    //const formObj =
    //{
    //  TraceId: "3",
    //  InventoryType: "0",
    //  RouteScheduleId: "19125",
    //  SourceId: 6558,
    //  DestinationId: 1584,
    //  JourneyDate: "2020-05-24T08:51:19.358Z",
    //  UserType: "User",
    //  UserId: ""
    //}

    //console.log(formObj);
    const requestObj = {
      type: 'POST',
      path: '/api/Bus/SeatLayout'
    };
    this.commonRequestService.request(requestObj, formObj, null, null).subscribe((res) => {
      this.seatLayout = res;

      if (this.seatLayout) {
        this.seatLayoutLower = this.seatLayout.Seats.filter(x => x.ZIndex == 0);
        this.seatLayoutUpper = this.seatLayout.Seats.filter(x => x.ZIndex == 1);
      }

      this.showSeats(i);
      //console.log(res);
    });
  }




  /**
  * Before calling this method we should have to pass seats array in sorted order.
  * 
  * In buslayout response seats Object has lower (zindex=0) and upper (zindex =1) we have to call this function upper seats array and lower seats array different calls.
  */

  generateLayout123(seats, zIndex) {

    var busLayoutHtmlTmpl = '';// This variable is used for seat layout template 
    var maxNoOfColumns = 0; // maximun no of columns out of all available rows;

    /*
    * finding path way logic start from here
    */

    var missingPathWayRowNO = -1;
    var missingPathWayRowNOTmp = 0
    var isfirtRow = true;
    var isDriverSeat = true;
    var rowSeatValues = [];
    var columnSeatValues = [];

    seats.forEach((columnSeats, rowIndex) => {
      if (isfirtRow) {
        missingPathWayRowNOTmp = rowIndex;
        isfirtRow = false;
      } else {
        if ((missingPathWayRowNOTmp) + 1 != rowIndex) {
          missingPathWayRowNO = (missingPathWayRowNOTmp) + 1;
        } else {
          missingPathWayRowNOTmp = (missingPathWayRowNOTmp) + 1;
        }
      }
      maxNoOfColumns = columnSeats.length > maxNoOfColumns ? columnSeats.length : maxNoOfColumns;
    });

    /*
    * finding path way logic end from here
    */


    /*
    * Loop for unique rows and culumn and keeping in array for display we are using this array
    */
    seats.forEach((columnSeats, rowIndex) => {
      if (rowSeatValues.indexOf(columnSeats.Row) === -1) {
        rowSeatValues.push(columnSeats.Row);

      }
      //if ($.inArray(seat.Row, rowSeatValues) === -1) {
      //  rowSeatValues.push(seat.Row);
      //}

      //if ($.inArray(seat.Column, columnSeatValues) === -1) {
      //  columnSeatValues.push(seat.Column);
      //}
      if (columnSeatValues.indexOf(columnSeats.Column) === -1) {
        columnSeatValues.push(columnSeats.Column);
      }

    });


    /*
    * To display seat layout in the form of matrix for that creating empty matrix with the help of unique rows and columns
    */
    var seatLayOutMax = new Array();
    var count = 0;
    rowSeatValues.forEach((row, columnIndex) => {
      seatLayOutMax[row] = new Array();
      columnSeatValues.forEach((seat, columnIndex) => {
        seatLayOutMax[row][seat] = "";
      });
    });



    /*
    * Asigning seat object to matrix
    */
    seats.forEach((seat, columnIndex) => {
      seatLayOutMax[seat.Row][seat.Column] = seat
    });


    /*
    * seat display html logic 
    */

    var pathComparision = 0;
    busLayoutHtmlTmpl += '<table>';
    seatLayOutMax.forEach((row, index) => {
      busLayoutHtmlTmpl += '<tr>';
      if (zIndex == 0 && isDriverSeat) {
        busLayoutHtmlTmpl += '<td><div class="seat-sprite driver-seat"></div></td>'; //this block is for dispaly driver seat
      } else {
        busLayoutHtmlTmpl += '<td><div></div></td>'; //giving empty speace not driver 
      }
      isDriverSeat = false;
      row.forEach((col, ind) => {
        var seat = col;
        pathComparision = seat.Row;
        if (seat != "") {
          var l = seat.Length;
          var w = seat.Width;
          if (l == 1 && w == 1) {
            busLayoutHtmlTmpl += '<td><div>seater</div></td>'; // Here have to show Seater/Semi Sleeper
          } else if (l == 1 && w == 2) {
            busLayoutHtmlTmpl += '<td><div>vertical sleeper</div></td>'; // Here have to show Vertical Sleeper
          } else if (l == 2 && w == 1) {
            if (rowSeatValues.length == 1) { //if row size one in case sleeps we are changing seat to vartical
              busLayoutHtmlTmpl += '<td rowspan="2"><div></div></td>'; // Here have to show Vertical Sleeper
            } else {
              busLayoutHtmlTmpl += '<td><div>horizontal</div></td>'; // Here have to show Horizontal Sleeper
            }
          } else {
            //l = 2 && w = 2 or other combo will never happen
          }
          busLayoutHtmlTmpl += '</td>';
        } else {
          busLayoutHtmlTmpl += '<td></td>';
        }
      });
      busLayoutHtmlTmpl += '</tr>';
      if (missingPathWayRowNO == (pathComparision) + 1) {
        busLayoutHtmlTmpl += '<tr><td><div>path</div></td><td></td></tr>';  // this is paht way
      }

    });
    isDriverSeat = false;
    busLayoutHtmlTmpl += '</table>';
    return busLayoutHtmlTmpl;
  }



  sourceLocation(value) {
    if (value) {
      this.searchParams.SourceId = value.CityId;
      this.sourceCity = value.CityName
    }
  }

  destLocation(value) {
    if (value) {
      this.searchParams.DestinationId = value.CityId;
      this.destCity = value.CityName
    }
  }

  selectedSeats(value, data, zIndex) {
    if (value) {

      if (zIndex == 0 || data.selectedLowerSeats == undefined) {
        data.selectedLowerSeats = [];
      }
      if (zIndex == 1 || data.selectedUpperSeats == undefined) {
        data.selectedUpperSeats = [];
      }


      data.basicFare = 0;
      data.gst = 0;
      data.serviceCharge = 0;
      data.amount = 0;
      data.totalCharges=0;
      data.selectedSeats = [];
      if (zIndex == 0) {
        value.forEach((seat, rowIndex) => {
          seat.forEach((innerseat, rowIndex) => {
            if (innerseat.selected && innerseat.Available) {
              innerseat.Name = "";
              innerseat.Age = "";
              innerseat.Gender = 'M';
              data.Email = "";
              data.Contact = "";
              data.Address = "";
              data.selectedLowerSeats.push(innerseat);
            }
          })
        })
      }

      if (zIndex == 1) {
        value.forEach((seat, rowIndex) => {

          seat.forEach((innerseat, rowIndex) => {
            if (innerseat.selected && innerseat.Available) {
              innerseat.Name = "";
              innerseat.Age = "";
              innerseat.Gender = 'M';
              data.Email = "";
              data.Contact = "";
              data.Address = "";
              data.selectedUpperSeats.push(innerseat);
            }
          })
        })
      }


      if (data) {
        //if (data.selectedLowerSeats != undefined) { data.selectedSeats.concat(data.selectedLowerSeats); }
        //if (data.selectedUpperSeats != undefined) { data.selectedSeats.concat(data.selectedUpperSeats); }
        data.selectedSeats = data.selectedLowerSeats.concat(data.selectedUpperSeats);

        data.selectedSeats.forEach((seat, rowIndex) => {
          data.basicFare = parseFloat(data.basicFare) + parseFloat(seat.OperatorBaseFare);
          data.gst = (parseFloat(data.gst) + parseFloat(seat.Fare) - parseFloat(seat.OperatorBaseFare)).toFixed(2);
          data.serviceCharge = (parseFloat(data.serviceCharge) + parseFloat(seat.ServiceTax)).toFixed(2);
          data.totalCharges = (parseFloat(data.gst) + parseFloat(data.serviceCharge)).toFixed(2);
        });

        //data.gst = data.basicFare * 0.05;
        //data.serviceCharge = parseFloat(data.serviceCharge) ;
        data.amount = parseFloat(data.basicFare) + parseFloat(data.gst) + parseFloat(data.serviceCharge);

      }

    }



  }

  getSelectedSeats(data) {
    if (data && data.selectedSeats)
      if(data.APIType == 3)
        return data.selectedSeats.map(r => r.SeatName).join();
       else
        return data.selectedSeats.map(r => r.Id).join();
  }

  submitBusRegistrationForm(data, seatData) {

    this.busRegistrationFormSubmitted = true;

    // stop here if form is invalid
    if (this.busRegistrationForm.invalid) {
      debugger;
      return;
    }


    var passengerList = [

    ];

    if (data) {
      data.selectedSeats.forEach((seats, rowIndex) => {
        passengerList.push(
          {
            Age: parseInt(seatData[rowIndex].controls.passengerAge.value),
            Name: seatData[rowIndex].controls.passengerName.value,
            SeatNo: seatData[rowIndex].controls.Id.value,
            Sex: seatData[rowIndex].controls.passengerGender.value,
            Fare: parseFloat(seats.Fare),
            ServiceTax: seats.ServiceTax,
            OperatorServiceCharge: seats.OperatorServiceCharge,
            LadiesSeat: seats.LadiesSeat,
            LastName: seatData[rowIndex].controls.passengerName.value,
            Title: seatData[rowIndex].controls.passengerGender.value == 'M' ? 'Mr' : 'Ms',
            NameOnId: "",
            Primary: true,
            Ac: seats.Ac,
            Sleeper: seats.Sleeper,
            SeatName: data.APIType == 3 ? data.selectedSeats.map(r => r.SeatName).join() :""
          }
        )
      });

    }
debugger;
    var obj = {
      TraceId: this.busesSearchResponse.TraceId,
      RouteScheduleId: data.RouteScheduleId,
      BoardingPoint: {
        Id: data.boarding,
        Location: data.BoardingPoints.find(function(o){ return o.Id===data.boarding }).Location,
        Time: ""
      },
      DroppingPoint: {
        Id: data.destination, 
        Location: data.DroppingPoints.find(function(o){ return o.Id===data.destination }).Location,
        Time: ""
      },
      CustomerEmail: data.Email,
      CustomerPhone: data.Contact,
      CustomerAddress: "Testing",//data.Address,
      Passangers: passengerList,
      InventoryType: data.InventoryType,
      SourceId: this.searchParams.SourceId,
      DestinationId: this.searchParams.DestinationId,
      JourneyDate: (this.searchParams.JourneyDate),
      UserType: this.user.role,
      UserId: this.user.userId,
      OperatorName: data.OperatorName,
      OperatorId: data.OperatorId,
      BusType: data.BusType,
      SupplierId: data.SupplierId,
      DepartureTime: data.DepartureTime,
      ArrivalTime: data.ArrivalTime,
      APIType: data.APIType,
      Tripid: data.Tripid,
      EzeeInfoTripCode: data.EzeeInfoTripCode,
      EzeeInfoTripStageCode: data.EzeeInfoTripStageCode
    }

    //var obj = {
    //  TraceId: "3",
    //  InventoryType: "0",
    //  RouteScheduleId: "19125",
    //  SourceId: this.searchParams.SourceId,
    //  DestinationId: this.searchParams.DestinationId,
    //  JourneyDate: this.searchParams.JourneyDate,
    //  UserType: "Admin",
    //  UserId: ""
    //}

    const requestObj = {
      type: 'POST',
      path: '/api/Bus/BlockTicket'
    };

    this.commonRequestService.request(requestObj, obj, null, null).subscribe((res) => {
      //console.log(res);
      if (res) {
        res.APIType=obj.APIType;
        this.openDialog(res);
      }
      //this.busesSearchResponse = res;
      //this.formDesiredResult(res);

    });
  }


  openDialog(res): void {

    const dialogRef = this.dialog.open(ModalComponent, {
      width: '300px',
      data: {
        bookingId: res.BlockTicketKey,
        status: res.Status.Success,
        message: res.Status.Message
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (res.Status.Success) {
        const requestObj = {
          type: 'POST',
          path: '/api/Bus/SeatBooking?blockTicketKey=' + res.BlockTicketKey+'&apiKey='+res.APIType
        };
        this.commonRequestService.request(requestObj, null, null, null).subscribe((res) => {
          //console.log(res);
          if (res) {
            if (res.TicketTemplate) {
              this.ticketTemplate = res.TicketTemplate;
              this.router.navigateByUrl('/dashboard/ticket');
            }
          }
          //this.busesSearchResponse = res;
          //this.formDesiredResult(res);

        });
      }
      // this.openTicketTemplate();
      // var obj ={ blockTicketKey:res.BlockTicketKey};
      //this.router.navigate(['/dashboard']);

      // this.initializeBusForm();
      // POST 
      //this.email = result;
    });
  }


  openTicketTemplate() {
    var newWindow = window.open('http://example.com/waiting.html', '_blank');
    newWindow.document.write(this.ticketTemplate);
    this.initializeBusForm();
  }

  ngOnDestroy() {
    this.dataService.inputData = this.ticketTemplate;
  }

}
