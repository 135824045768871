<!-- <form novalidate [formGroup]="form" class="sidenav border border-color-8 rounded-xs">
    <div class="">
      <div class="d-flex flex-column card-body px-4 py-2">
        <div class="btn btn-link btn-block card-btn py-2 px-5 text-lh-3">
          <span class="font-weight-bold font-size-17 text-dark mb-3">Departure Time</span>     
          <span class="fas fa-chevron-down small"></span>     
        </div>

        <div class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-3">
          <mat-checkbox formControlName="depBefore6AM" (change)="search(form.value)">
            Before 6 am
          </mat-checkbox>
        </div>
        <div class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-3">
          <mat-checkbox formControlName="depBetween6AMAnd12PM" (change)="search(form.value)">
            6 am to 12pm
          </mat-checkbox>
        </div>
        <div class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-3">
          <mat-checkbox formControlName="depBetween12PMAnd6PM" (change)="search(form.value)">
            12pm to 6 pm
          </mat-checkbox>
        </div>
        <div class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-3">
          <mat-checkbox formControlName="depAfter6PM" (change)="search(form.value)">
            After 6 pm
          </mat-checkbox>
        </div>


        <div class="btn btn-link btn-block card-btn py-2 px-5 text-lh-3">
          <span class="font-weight-bold font-size-17 text-dark mb-3"> Boarding point</span>     
          <span class="fas fa-chevron-down small"></span>     
        </div>
        <div>

          <label formArrayName="orders" *ngFor="let order of form.get('orders')['controls']; let i = index">
            <mat-checkbox [formControlName]="i" (change)="search(form.value)">
              {{boardingPoints[i].Location |slice:0:20}}
            </mat-checkbox>
          </label>
        </div>

      </div>
    </div>
  </form> -->

<form novalidate [formGroup]="form" class="sidenav border border-color-8 rounded-xs">
  <mat-accordion [multi]="true">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Departure Time
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="panel-content-sd">
        <div class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-1">
          <mat-checkbox formControlName="depBefore6AM" (change)="search(form.value)">
            Before 6 am
          </mat-checkbox>
        </div>
        <div class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-1">
          <mat-checkbox formControlName="depBetween6AMAnd12PM" (change)="search(form.value)">
            6 am to 12pm
          </mat-checkbox>
        </div>
        <div class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-1">
          <mat-checkbox formControlName="depBetween12PMAnd6PM" (change)="search(form.value)">
            12pm to 6 pm
          </mat-checkbox>
        </div>
        <div class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-1">
          <mat-checkbox formControlName="depAfter6PM" (change)="search(form.value)">
            After 6 pm
          </mat-checkbox>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="false" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Boarding point
        </mat-panel-title>
        <!-- <mat-panel-description>
          Currently I am {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description> -->
      </mat-expansion-panel-header>
      <div class="panel-content-sd">
        <div formArrayName="boardingPoints" *ngFor="let boardingPoint of form.get('boardingPoints')['controls']; let i = index" class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-1">
          <mat-checkbox [formControlName]="i" (change)="search(form.value)">
            {{_data.boardingPointsList[i].Location |slice:0:20}}
          </mat-checkbox>
        </div>
      </div>
    </mat-expansion-panel>

    <!-- <mat-expansion-panel [expanded]="false" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Bus Types
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="panel-content-sd">
        <div formArrayName="busTypes" *ngFor="let busType of form.get('busTypes')['controls']; let i = index" class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-1">
          <mat-checkbox [formControlName]="i" (change)="search(form.value)">
            {{_data.busTypeList[i].busType |slice:0:20}}
          </mat-checkbox>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="false" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Operators
        </mat-panel-title>
        <mat-panel-description>
          Currently I am {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-content-sd">
        <div formArrayName="operators" *ngFor="let operator of form.get('operators')['controls']; let i = index" class="form-group d-flex align-items-center justify-content-between font-size-1 text-lh-md text-gray-1 mb-1">
          <mat-checkbox [formControlName]="i" (change)="search(form.value)">
            {{_data.operatorList[i].OperatorName |slice:0:20}}
          </mat-checkbox>
        </div>
      </div>
    </mat-expansion-panel>


    <mat-expansion-panel [expanded]="false" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Price Range
        </mat-panel-title>
        <mat-panel-description>
          Currently I am {{panelOpenState ? 'open' : 'closed'}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="panel-content-sd">
        <ng5-slider formControlName="sliderControl"  [value]="_data.minPrice" [highValue]="_data.maxPrice"   [options]="_data.options" (userChangeEnd)="search(form.value)"></ng5-slider>
      </div>
    </mat-expansion-panel> -->
  </mat-accordion>

</form>