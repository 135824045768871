import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, of} from "rxjs";
import { Router,ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../../environments/environment';
//import * as sha256 from 'crypto-js/sha256';
import { AgentListService } from '../../../../../views/admin-views/dashboard/agent-list/agent-list.service';
import { DashboardService } from '../../../../../views/admin-views/dashboard/dashboard.service';

@Component({
  selector: 'agent-header',
  templateUrl: './agent-header.component.html',
  styleUrls: ['./agent-header.component.scss']
})
export class AgentHeaderComponent implements OnInit {
  @Input() Header = true;
    showData:boolean = false;
    showSupplier:boolean = false;
    showCategory:boolean = false;
    showCommission:boolean = false;
    showBankDetails:boolean = false;
    showBankUserDetails: boolean = false;
    showBuses: boolean = false;
    agent:boolean = false;
    registration:boolean = false;
    depositForm:boolean = false;
    depositReports:boolean = false;
    revokeBalance:boolean = false;
    loadHeaderFooter:boolean = false;
    agentBankDetails:boolean = false;
    user:any;
    firstName: string;
    lastName: string;
    userId: number;
    balance: number;
    ScreenConfiguration: any = [];
    isBuses: boolean=false;
    isFlights: boolean=false;
    isAccounts: boolean=false;
    isSupplier: boolean=false;
    isCategory: boolean=false;
    isOperators: boolean=false;
    isBusCommission: boolean=false;
    isFlightsCommission: boolean=false;
    isBankDetails: boolean=false;
    isUserBankDetails: boolean=false;
    isRegister: boolean=false;
    isTransactions: boolean=false;
    isRequestForm: boolean=false;
    isReports: boolean=false;
    isAddRevokebalance: boolean=false;
    isAcceptRejectDeposit: boolean=false;
    isAgentBankDetails: boolean=false;
    isBusTicketDetails:boolean=false;
    isUserList:boolean=false;
    isAgentList:boolean=false;
    isDistributorList:boolean=false;
    isEmployeeList:boolean=false;
    isAccountStatement:boolean=false;
    isBusBookingReport:boolean=false;
    isFlightBookingReport:boolean=false;
    isCancelTicket:boolean=false;
    isReportsMenu:boolean=true;
    isMarkupCommissionBookingReport:boolean=false;
    loadChangePassword: boolean;
    resetTransaction: boolean;
    password: boolean;
    //role: any;
    showAcceptReject: boolean;
    showAccStatement: boolean;
    showUserList: boolean;
    showAgentList: boolean;
    showFlights: boolean;
    code: any;
    showBalance: boolean = true;
    isAgentEdit: boolean = false;
    showProfile: boolean = false;
    showFlightCommission: boolean;
    showBusTicketDetails: boolean;
   showFlightBooking:boolean =false;
   accountsOrAdminLabel:string;
   busCommissionOrMarkupLabel:string;
   flightCommissionOrMarkupLabel:string;
   agentOrDistributorListLabel:string;

    constructor(private dashboardService:DashboardService, private agentListService:AgentListService, private router: Router,private route: ActivatedRoute) 
    { 
    //   this.route.queryParams.subscribe(params => {
    //     var showFlightBooking = params["loadFlightBooking"];
    //     if(showFlightBooking)
    //   {
    //         //   this.loadFlightBooking();
    //   }
    // });
  
    }

    ngOnInit(): void {
     
        this.agent = true;
        this.dashboardService.setValue(this.loadHeaderFooter);
        this.agentListService.getValue().subscribe(res =>{
         this.registration = res;
         this.showAgentList = false;
       })
        this.user = JSON.parse(localStorage.getItem('userInfo'));
        if(this.user){
        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
        this.userId = this.user.userId;
        this.balance = this.user.balance;
        this.code = this.user.Code; 
        //this.role = this.user.role;
        this.accountsOrAdminLabel ="Accounts";
        this.busCommissionOrMarkupLabel = "Bus Commission";
        this.flightCommissionOrMarkupLabel = "Flight Commission";
        this.agentOrDistributorListLabel = "Agent List";
        if(this.user.role == 4){
            this.showBuses = true;
            this.busCommissionOrMarkupLabel = "Bus Markup";
            this.flightCommissionOrMarkupLabel = "Flight Markup";
          } else{
            this.showBankUserDetails = true;
          }
        
          if(this.user.role == 2){
            this.showBalance = false;
            this.accountsOrAdminLabel = "Admin";
            this.agentOrDistributorListLabel = "Agent List";
          }
          if(this.user.role == 3)
          {
            this.isReportsMenu = false;
          }
          
    
      }
    
        this.ScreenConfiguration = JSON.parse(localStorage.getItem('roles'));
        if(this.ScreenConfiguration){
        this.ScreenConfiguration.forEach(res =>{
           if(res.Name == 'buses'){
             this.isBuses = res.IsEnabled;
           } 
           if(res.Name == 'flights'){
             this.isFlights = res.IsEnabled;
           }  
           if(res.Name == 'account'){
             this.isAccounts = res.IsEnabled;
           }  
           if(res.Name == 'supplier'){
             this.isSupplier = res.IsEnabled;
           }  
           if(res.Name == 'category'){
             this.isCategory = res.IsEnabled;
           }  
           if(res.Name == 'operators'){
             this.isOperators = res.IsEnabled;
           }  
           if(res.Name == 'bus-commission'){
             this.isBusCommission = res.IsEnabled;
           }  
           if(res.Name == 'flight-commission'){
             this.isFlightsCommission = res.IsEnabled;
           }  
           if(res.Name == 'bank-details'){
             this.isBankDetails = res.IsEnabled;
           }  
          if (res.Name == 'bank-user-details'){
             this.isUserBankDetails = res.IsEnabled;
           }  
           if(res.Name == 'registration'){
             this.isRegister = res.IsEnabled;
           }  
           if(res.Name == 'Transactions'){
             this.isTransactions = res.IsEnabled;
           }  
           if(res.Name == 'deposit-form'){
             this.isRequestForm = res.IsEnabled;
           }  
           if(res.Name == 'deposit-report'){
             this.isReports = res.IsEnabled;
           }  
           if(res.Name == 'add-revoke-balance'){
             this.isAddRevokebalance = res.IsEnabled;
           } 
           if(res.Name == 'accept-reject-deposit'){
             this.isAcceptRejectDeposit = res.IsEnabled;
           } 
           if(res.Name == 'agent-bank-details'){
             this.isAgentBankDetails = res.IsEnabled;
           }
           if(res.Name == 'bus-ticket-details'){
            this.isBusTicketDetails = res.IsEnabled;
          }
          if(res.Name == 'user-list'){
            this.isUserList = res.IsEnabled;
          }
          if(res.Name == 'agent-list'){
            this.isAgentList = res.IsEnabled;
          }
          if(res.Name == 'distributor-list'){
            this.isDistributorList = res.IsEnabled;
          }
          if(res.Name == 'employee-list'){
            this.isEmployeeList = res.IsEnabled;
          }
          if(res.Name == 'account-statement'){
            this.isAccountStatement = res.IsEnabled;
          }
          if(res.Name == 'bus-booking-report'){
            this.isBusBookingReport = res.IsEnabled;
          } 
          if(res.Name == 'flight-booking-report'){
            this.isFlightBookingReport = res.IsEnabled;
          }  
          if(res.Name == 'cancel-ticket'){
            this.isCancelTicket = res.IsEnabled;
          }          
          if(res.Name == 'markup-commission-report'){
            this.isMarkupCommissionBookingReport = res.IsEnabled;
          }
           
        })
        }
       
      }

      
  logout(){
    this.loadHeaderFooter = true;
    this.dashboardService.setValue(this.loadHeaderFooter);
    localStorage.removeItem('roles');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('token');
    this.router.navigate(['/home']);
  }

  refresh(){
    this.dashboardService.Refresh().subscribe((res:any) => {
      this.balance = res.Data;
    })
  }
  
}