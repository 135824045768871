import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonRequestService } from 'src/app/shared/services/http/common-request.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  showBusesSearch: any = false;
  showFlightsSearch: any = true;
  busList: any = [];
  busForm: FormGroup;

  busFormFinal:any= {
    TraceId:'',
    SourceId:'',
    DestinationId:'',
    JourneyDate:''
  };

  constructor(
    private router: Router,
    private commonRequestService: CommonRequestService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    /**
     * For development purpose
     * As there are 20000 records calling API only once
     * and storing those values in localStore
     */
    this.initializeBusForm();
    // if (localStorage.busList) {
    //   this.busList = JSON.parse(localStorage.busList).slice(0, 200);
    //   if (this.busList.length === 0) {
    //     this.getBusList();
    //   }
    // } else {
    //   this.getBusList();
    // }
  }

  private initializeBusForm  () {
    this.busForm = this.formBuilder.group({
      TraceId: ['123'],
      SourceId: ['', Validators.compose([Validators.required])],
      DestinationId: ['', Validators.compose([Validators.required])],
      JourneyDate: [new Date().toISOString().slice(0, 16), Validators.compose([Validators.required])]
    });
  }

  // convenience getter for easy access to form fields
  get busFormControls() { return this.busForm.controls; }

  busFormSubmit() {
    console.log(this.busForm.value);
    //this.busForm = this.formBuilder.group({
    //  TraceId: ['123'],
    //  SourceId: ['', Validators.compose([Validators.required])],
    //  DestinationId: ['', Validators.compose([Validators.required])],
    //  JourneyDate: [new Date().toISOString().slice(0, 16), Validators.compose([Validators.required])]
    //});

    //Sample data
    this.busFormFinal.SourceId= 6558,
      this.busFormFinal.DestinationId= 1584,
      this.busFormFinal.JourneyDate= "2020-05-24T08:51:19.358Z"
   
    this.router.navigate(['/buses', this.busFormFinal.SourceId, this.busFormFinal.DestinationId, this.busFormFinal.JourneyDate]);



  }

  goToBusListPage() {
    this.router.navigate(['/buses']);
  }
  showBuses() {
    this.showBusesSearch = true;
    this.showFlightsSearch = false;
  }

  showFlights() {
    this.showFlightsSearch = true;
    this.showBusesSearch = false;
  }

  private getBusList() {
    const requestObj = {
      type: 'GET',
      path: '/api/Bus/GetCityList'
    };
    this.commonRequestService.request(requestObj, null, null, null).subscribe((res) => {
      console.log(res);
      this.busList = res;
      localStorage.busList = JSON.stringify(res);
    });
  }


  //-------------------Setting source and destination location

  sourceLocation(value) {
    this.busFormFinal.SourceId = value.CityId;    
  }

  destLocation(value) {
    this.busFormFinal.DestinationId = value.CityId;
  }


}
