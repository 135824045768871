
<div mat-dialog-actions>
    <!-- <button type="button" mat-button (click)="openInvoice()">Invoice</button>
    <button type="button" mat-button (click)="downloadInvoice()">Download</button>
    <button type="button" mat-button (click)="smsInvoice()">Sms</button>
    <button type="button" mat-button (click)="emailInvoice()">Email</button> -->
    <!-- <button mat-button *ngIf="editVal" cdkFocusInitial >Update</button> -->
  </div>

<!-- <mat-icon color="accent" (click)="openPrintDialog()">print</mat-icon> -->
<!-- <mat-icon color="accent" (click)="openInvoice()">print</mat-icon> -->
<div [innerHtml]="initialTemplate" id="invoicePDF">

</div>
