<div class="seat-select-sec">
  <div class="bus-data">
    <div class="layout-sec">

      <!----------------------------LOWER------------------------------------------------------------->

      <div class="bus-body">
        <span class="side front">Front</span>
        <span class="side rear">Rear</span>
        <div class="first-row">
          <div class="driver" *ngIf="zIndex==0">
            <img src="../assets/images/steering-wheel.png" alt="steering">
          </div>
          <div class="entry" *ngIf="zIndex==0">
            LoWER
          </div>
          <div class="entry" *ngIf="zIndex==1">
            UPPER
          </div>

        </div>
        <div class="seat-layout">
          <div class="seat-row" *ngFor="let row of seatLayOutMax;let i= index" (click)="onSelect(seatLayOutMax)">
            {{i=== missingPathWayRowNO?'&nbsp;':''}}
            <div *ngFor="let j of row">
              <!-- <div>Testing</div> -->
              <div *ngIf="j && j.Length==1 && j.Width==1">

                <div class="seat-sec" (click)="j.selected=!j.selected">
                  <div [ngClass]="j.Available?j.selected==true?'seat selected':j.LadiesSeat==true?'seat ladies-available':'seat':j.LadiesSeat==true?'seat ladies-booked':'seat occupied'" data-toggle="tooltip" data-placement="top" [title]="j.Fare">
                    <div class="seat-no">{{j.SeatName?j.SeatName:j.Id}}</div>
                    <span class="seater rt"></span>
                    <span class="seater lt"></span>
                    <span class="seater bk"></span>
                  </div>
                </div>
              </div>
              <div *ngIf="j && j.Length==2 && j.Width==1">
                <div *ngIf="rowSeatValues.length==1">
                  <div>seater</div>
                </div>
                <div *ngIf="rowSeatValues.length>1">

                  <div class="seat-sec" (click)="j.selected=!j.selected">
                    <div [ngClass]="j.Available?j.selected==true?'Sleeper selected':j.LadiesSeat==true?'Sleeper ladies-available':'Sleeper':j.LadiesSeat==true?'Sleeper ladies-booked':'Sleeper occupied'" data-toggle="tooltip" data-placement="top" [title]="j.Fare">
                      <div class="seat-no">{{j.SeatName?j.SeatName:j.Id}}</div>
                      <span class="seater rt"></span>
                      <span class="seater lt"></span>
                      <span class="seater bk"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="j && j.Length==1 && j.Width==2">
                <div *ngIf="rowSeatValues.length==1">
                  <div>seater</div>
                </div>
                <div *ngIf="rowSeatValues.Width>1">

                  <div class="seat-sec" (click)="j.selected=!j.selected">
                    <div [ngClass]="j.Available?j.selected==true?'Sleeper selected':j.LadiesSeat==true?'Sleeper ladies-available':'Sleeper':j.LadiesSeat==true?'Sleeper ladies-booked':'Sleeper occupied'" data-toggle="tooltip" data-placement="top" [title]="j.Fare">
                      <div class="seat-no">{{j.SeatName?j.SeatName:j.Id}}</div>
                      <span class="seater rt"></span>
                      <span class="seater lt"></span>
                      <span class="seater bk"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="j && j.Length==2 && j.Width==2">
                
                <div *ngIf="rowSeatValues.length==1">
                  <div>seater</div>
                </div>
                <div >

                  <div class="seat-sec">
                    <div>
                      <div class="seat-no">&nbsp;</div>
                      <span class="seater rt"></span>
                      <span class="seater lt"></span>
                      <span class="seater bk"></span>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>



     
    </div>
    <div class="seat-for" *ngIf="zIndex==1 || zIndex==2">
      <div class="seat-spec">
        <div class="seat selected">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Selected Seat</label>
      </div>
      <div class="seat-spec">
        <div class="seat ladies-booked">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Booked By Ladies</label>
      </div>
      <div class="seat-spec">
        <div class="seat occupied">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Occupied Seat</label>
      </div>
      <div class="seat-spec">
        <div class="seat">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Available Seat</label>
      </div>
      <div class="seat-spec">
        <div class="seat ladies-available">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Reserved for Ladies</label>
      </div>
    </div>
  </div>
</div>



<div class="seat-select-sec" *ngIf="false">
  <div class="bus-data">
    <div class="layout-sec">
      <div class="bus-body">
        <span class="side front">Front</span>
        <span class="side rear">Rear</span>
        <div class="first-row">
          <div class="driver">
            <img src="../assets/images/steering-wheel.png" alt="steering">
          </div>
          <div class="entry"></div>
        </div>
        <div class="seat-layout">
          <div class="seat-row">
            <div>
              <div>
                <div class="seat-sec">
                  <div class="seat selected">
                    <div class="seat-no">A1</div>
                    <span class="seater rt"></span>
                    <span class="seater lt"></span>
                    <span class="seater bk"></span>
                  </div>
                </div>
              </div>
            </div>
              <div class="seat-sec">
                <div class="seat occupied">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
            </div>

            <div class="seat-row">
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
            </div>

            <div class="seat-row">
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
            </div>

            <div class="seat-row">
              <div class="seat-sec">
                <div class="seat ladies-booked">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
            </div>

            <div class="seat-row">
              <div class="seat-sec">
                <div class="seat ladies-available">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
              <div class="seat-sec">
                <div class="seat">
                  <div class="seat-no">A1</div>
                  <span class="seater rt"></span>
                  <span class="seater lt"></span>
                  <span class="seater bk"></span>
                </div>
              </div>
            </div>
          </div>
      </div>

    </div>
    <div class="seat-for">
      <div class="seat-spec">
        <div class="seat selected">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Selected Seat</label>
      </div>
      <div class="seat-spec">
        <div class="seat ladies-booked">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Booked By Ladies</label>
      </div>
      <div class="seat-spec">
        <div class="seat occupied">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Occupied Seat</label>
      </div>
      <div class="seat-spec">
        <div class="seat">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Available Seat</label>
      </div>
      <div class="seat-spec">
        <div class="seat ladies-available">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Reserved for Ladies</label>
      </div>
    </div>
  </div>
  <div class="fare-details"></div>
</div>


<div class="seat-select-sec" *ngIf="false">
  <div class="bus-data">
    <div class="layout-sec">
      <div class="bus-body">
        <span class="side front">Front</span>
        <span class="side rear">Rear</span>
        <div class="first-row">
          <div class="driver">
            <img src="../assets/images/steering-wheel.png" alt="steering">
          </div>
          <div class="entry">LOWER</div>
        </div>
        <div class="seat-layout">
          <div class="seat-row">
            <div class="seat-sec">
              <div class="seat selected">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat occupied">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat occupied">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
          </div>

          <div class="seat-row">
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
          </div>

          <div class="seat-row">
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
          </div>

          <div class="seat-row">
            <div class="seat-sec">
              <div class="seat ladies-booked">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
          </div>

          <div class="seat-row">
            <div class="seat-sec">
              <div class="seat ladies-available">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="seat">
                <div class="seat-no">A1</div>
                <span class="seater rt"></span>
                <span class="seater lt"></span>
                <span class="seater bk"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bus-body">
        <span class="side front">Front</span><span class="side rear">Rear</span>
        <div class="first-row">
          <div class="driver">
            <img src="../assets/images/steering-wheel.png" alt="steering">
          </div>
          <div class="entry">UPPER</div>
        </div>
        <div class="seat-layout">
          <div class="seat-row sleeper-s">
            <div class="seat-sec">
              <div class="Sleeper selected">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper occupied">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper ladies-booked">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
          </div>
          <div class="seat-row sleeper-s">
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper ladies-available">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
          </div>
          <!-- empty seat -->
          <div class="seat-row sleeper-s">

          </div>
          <div class="seat-row sleeper-s">
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
            <div class="seat-sec">
              <div class="Sleeper">
                <div class="seat-no">S1</div>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="seat-for">
      <div class="seat-spec">
        <div class="seat selected">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Selected Seat</label>
      </div>
      <div class="seat-spec">
        <div class="seat ladies-booked">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Booked By Ladies</label>
      </div>
      <div class="seat-spec">
        <div class="seat occupied">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Occupied Seat</label>
      </div>
      <div class="seat-spec">
        <div class="seat">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Available Seat</label>
      </div>
      <div class="seat-spec">
        <div class="seat ladies-available">
          <div class="seat-no"></div>
          <span class="seater rt"></span>
          <span class="seater lt"></span>
          <span class="seater bk"></span>
        </div>
        <label>Reserved for Ladies</label>
      </div>
    </div>
  </div>
  <div class="fare-details"></div>
</div>
